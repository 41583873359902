package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.Images
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.graphics.Image

@Composable
fun AppBackImage() {
    Image(
        modifier = Modifier
            .fillMaxSize()
            .zIndex(-20)
            .objectFit(ObjectFit.Cover),
        src = Images.AppBackGround2,
        alt = "Background Image"
    )
}
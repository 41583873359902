package com.kelimesoft.etutpro.network

import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.utils.rountTo
import kotlinx.browser.window
import kotlinx.coroutines.await
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import org.w3c.files.File
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.json


object RestApi {
    //const val WebRoot = "http://localhost:9020/" //local testlerde bu
    const val WebRoot = "https://etutpro.com/" //local testlerde bu
    //const val WebRoot = "/" //web sitesinde bu
    const val ApiRoot = WebRoot + "api1/"

    const val connectErr = "{\"data\":null, error=\"Connection Error\"}"


    val jsonX = Json { encodeDefaults = true }

    suspend fun userLogin(formData: FormData): String? {
        return postForm("${ApiRoot}login", formData)
    }

    suspend fun getAppUser(): String? {
        return postForm("${ApiRoot}getuser", FormData())
    }
    suspend fun registerUSer(user: NewUser): String? {
        //console.log("regogrenci:", ogrenci.toString())
        return postJson("${ApiRoot}register", jsonX.encodeToString(user))
    }

    suspend fun forgotPass(formData: FormData): String? {
        return postForm("${ApiRoot}forgotpass", formData)
    }

    suspend fun getDersList(uuid: String? = null): String? {
        val formData = FormData()
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}derslist", formData)
    }

    suspend fun addNewDers(dersItem: DersItem, uuid: String? = null): String? {
        val dersBinding =  NewDersBinding(ders = dersItem)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}addders", jsonX.encodeToString(dersBinding))
    }

    suspend fun updateDersSira(sira: Int, gun: Int, dersItem: DersItem, uuid: String? = null): String? {
        val dersBinding =  DersOrderBinding(ders = dersItem, gun = gun, sira = sira)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}dersira", jsonX.encodeToString(dersBinding))
    }

    suspend fun deleteDers(id: Long,gun: Int, sira: Int, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("id", id.toString())
        formData.append("gun", gun.toString())
        formData.append("sira", sira.toString())
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}delders", formData)
    }

    suspend fun getEtutList(start: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("start", start)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}etutlist", formData)
    }

    suspend fun getEtutSearch(text: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("text", text)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}etutsearch", formData)
    }

    suspend fun getGunEtutList(day: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("day", day)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}gunetut", formData)
    }

    suspend fun uploadBase64Image(image: String): String? {
        val formData = FormData()
        formData.append("image", image)
        return postForm("${ApiRoot}tempimage", formData)
    }

    suspend fun uploadTempPdf(pdf: String): String? {
        val formData = FormData()
        formData.append("pdf", pdf)
        return postForm("${ApiRoot}temppdf", formData)
    }


    suspend fun getKaynakList(uuid: String? = null): String? {
        val formData = FormData()
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}kaynaklist", formData)
    }

    suspend fun addNewKaynak(kaynak: DersKaynak, uuid: String? = null, uuids: List<String> = listOf()): String? {
        val kayBinding =  NewKaynakBinding(kaynak= kaynak, uuids = uuids)
        if (uuid != null){
            kayBinding.uuid = uuid
        }
        console.log("kaynakadd: ", kayBinding.toString())
        return postJson("${ApiRoot}addkaynak", jsonX.encodeToString(kayBinding))
    }

    suspend fun getOgrenciList(): String? {
        return postForm("${ApiRoot}ogrencilist", FormData())
    }

    suspend fun addNewOgrenci(form: FormData): String? {
        return postForm("${ApiRoot}addogrenci", form)
    }

    suspend fun addNewEtut(etutItem: EtutItem, start: String, uuid: String? = null,
                           docs: List<String> = listOf(), uuids: List<String> = listOf()): String? {
        val etutBinding =  NewEtutBinding(etut = etutItem, start = start, docs = docs, uuids = uuids)
        if (uuid != null){
            etutBinding.uuid = uuid
        }
        console.log("etutadd: ", etutBinding.toString())
        return postJson("${ApiRoot}addetut", jsonX.encodeToString(etutBinding))
    }

    suspend fun deleteEtut(id: Long): String? {
        val formData = FormData()
        formData.append("id", id.toString())
        return postForm("${ApiRoot}deletut", formData)
    }
    suspend fun replanUnfinished(start: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("start", start)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}replan", formData)
    }


    suspend fun editExistingEtut(etutItem: EtutItem, uuid: String? = null): String? {
        val etutBinding =  NewEtutBinding(etut = etutItem, start = "")
        if (uuid != null){
            etutBinding.uuid = uuid
        }
        return postJson("${ApiRoot}editetut", jsonX.encodeToString(etutBinding))
    }

    suspend fun etutCompleted(id: Long, done: Boolean, puan: Double = 0.0,
                              dogru: Int = 0, yanlis: Int = 0): String? {
        val formData = FormData()
        formData.append("id", id.toString())
        formData.append("done", if (done) "1" else "0")
        formData.append("puan", puan.rountTo(1).toString())
        formData.append("dogru", dogru.toString())
        formData.append("yanlis", yanlis.toString())
        return postForm("${ApiRoot}etutdone", formData)
    }


    suspend fun getHedefList(uuid: String? = null): String? {
        val formData = FormData()
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}hedeflist", formData)
    }

    suspend fun getFaliyetOzet(start: String, uuid: String? = null): String? {
        val formData = FormData()
        formData.append("start", start)
        if (uuid != null){
            formData.append("uuid", uuid)
        }
        return postForm("${ApiRoot}faalozet", formData)
    }

    suspend fun addNewHedef(hedef: Hedef, uuid: String? = null): String? {
        val dersBinding =  NewHedefBinding(hedef = hedef)
        if (uuid != null){
            dersBinding.uuid = uuid
        }
        return postJson("${ApiRoot}addhedef", jsonX.encodeToString(dersBinding))
    }


    fun uploadFile(file: File, onComplete: (String?) -> Unit) {
        val formData = FormData()
        formData.append("file", file)
        val xhr = XMLHttpRequest()
        xhr.open("POST", "/upload", true)
        // Set the Content-Type header to "multipart/form-data"
        xhr.setRequestHeader("Content-Type", "multipart/form-data")
        xhr.onload = { _ ->
            if (xhr.status.toInt() == 200) {
                console.log("File uploaded successfully!")
                onComplete(xhr.responseText)
            } else {
                console.error("File upload failed.")
            }
        }
        xhr.send(formData)
    }


    private suspend fun postForm(url: String, body: FormData? = null): String? {
        var form = body
        if (form != null){
            val uuid: String = form.get("uuid") as? String ?: ""
            if (uuid.isEmpty()){
                var userId = AppData.appUser.uuid
                if (userId.isEmpty()){
                    userId = AppStorage.getString(AppStorage.userUUID)
                }
                form.set("uuid", userId)
            }
        }else{
            form = FormData()
            form.set("uuid", AppData.appUser.uuid)
        }
        val res = window.fetch(url, object : RequestInit {
            override var method: String? = "POST"
            override var body: FormData? = form
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()
        return if (res.ok) JSON.stringify(res.json().await()) else null
    }


    private suspend fun postJson(url: String, json: String? = null): String? {
        val res = window.fetch(url, object : RequestInit {
            override var method: String? = "POST"
            override var body: String? = json
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()
        return if (res.ok) JSON.stringify(res.json().await()) else null
    }

    private suspend fun apiGet(url: String): String? {
        val res = window.fetch(url, object : RequestInit {
            override var method: String? = "GET"
            override var headers: dynamic = json("Accept" to "application/json")
        }).await()
        return if (res.ok) JSON.stringify(res.json().await()) else null
    }
}









package com.kelimesoft.etutpro.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlin.js.Date

enum class EtutType(val type: Int, val text: String, val icon: String){
    KonuTest(0, "Konu Testi / Etkinlik", "file-signature"),
    Konu(1, "Konu Anlatımı", "file-contract"),
    Deneme(2, "Genel Test / Deneme", "clock"),
    Kitap(3, "Kitap Oku", "book"),
    Kuran(4, "Kur'an Oku", "book-kuran")

}

@Serializable
data class EtutItem(
    val id: Long,
    var ders: String,
    var saati: String,
    var konu: String,
    var kaynak: String,
    var soru: Int,
    var puan: Double,
    var dogru: Int = 0,
    var yanlis: Int = 0,
    var type: Int,
    var link: String,
    var sayfa: String,
    var tarih: String,
    @SerialName("by")
    var addBy: Int,
    var done: Boolean = false
)





@Serializable
data class NewEtutBinding(
    var uuid: String = AppData.appUser.uuid,
    val start: String,
    val etut: EtutItem,
    val docs: List<String> = listOf(),
    val uuids: List<String> = listOf()
)



package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.components.NoContentYet
import com.kelimesoft.etutpro.models.Routes
import com.kelimesoft.etutpro.models.Screens
import com.varabyte.kobweb.core.Page

@Page("/profil")
@Composable
fun Profil() {
    IfUserExist(Screens.Profil) {
        RouterPage()
    }
}

@Composable
fun ProfilPage() {
    NoContentYet("Henüz tamamlanmadı")
}
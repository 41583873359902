package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.components.NoContentYet
import com.varabyte.kobweb.core.Page

@Page("/page404")
@Composable
fun Page404() {
    NoContentYet("404-Aradığınız sayfa bulunamadı")
}
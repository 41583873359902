package com.kelimesoft.etutpro.pages.kaynak

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.ozet.OzetSubFaaliyet
import com.kelimesoft.etutpro.styles.FaaliyetDersChevStyle
import com.kelimesoft.etutpro.styles.OzetDersColStyle
import com.kelimesoft.etutpro.viewmodels.EtutVM
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronDown
import com.varabyte.kobweb.silk.components.icons.fa.FaChevronRight
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun EtutKaynakList(etutVM: EtutVM, onDrag: (DersKaynak) -> Unit){
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    val kaynakVM = AppData.ViewModel.kaynakVM
    val dersVM = AppData.ViewModel.dersVM

    var uuid: String? by remember { mutableStateOf(null) }
    var expandedDers = remember { mutableStateOf("") }
    var allSoruPuan by remember { mutableStateOf(0) }

    LaunchedEffect(etutVM.etutListDersMap){
        var sorupuan = 0
        etutVM.allEtutList.forEach {
            if (it.type == 0){
                sorupuan += it.soru
            }else if (it.type == 1){
                sorupuan+=it.puan.toInt()
            }
        }
        allSoruPuan = sorupuan
    }

    LaunchedEffect(ogrenciVM.selectedOgrenci, AppData.appUser.role, kaynakVM.kaynakMap){
        if (AppData.appUser.role > 0 && ogrenciVM.selectedOgrenci != null) {
            uuid = ogrenciVM.selectedOgrenci!!.uuid
        }else {
            uuid = AppData.appUser.uuid
        }
        kaynakVM.getKaynakList(uuid)

    }

    Column(modifier = Modifier
        .fillMaxHeight()
        .backgroundColor(AppColors.Gray.opacity(0.4f))
        .width(250.px)
    ) {
        OzetSubFaaliyet(
            title = "Dersler / Soru: $allSoruPuan",
            fontSize = 13.px
        ){
            kaynakVM.kaynakMap.forEach { map ->
                var color = AppColors.colorDersList[(0..8).random()]
                dersVM.dersList.find { it.dersad == map.key }?.let {
                    color = AppColors.colorDersList[it.renk]
                }
                Column(
                    modifier = OzetDersColStyle
                        .toModifier()
                        .borderRadius(4.px)
                        .backgroundColor(color.opacity(0.3f))
                        .fillMaxWidth()
                ) {
                    KaynakDersView(etutVM, map, expandedDers)
                    if (expandedDers.value == map.key) {
                        map.value.forEach { kaynak ->
                            KaynakInfo(etutVM, map.key, kaynak){
                                onDrag(kaynak)
                            }
                        }
                    }


                }

            }
        }
        SpanText("Kaynakları sürükleyerek etüt ekleyebilirsiniz.",
            modifier = Modifier
                .color(AppColors.DarkRed)
                .fontSize(FontSize.Smaller)
                .padding(6.px)
        )

    }

}

@Composable
fun KaynakDersView(
    etutVM: EtutVM,
    dersMap: Map.Entry<String, List<DersKaynak>>, expanded: MutableState<String>
) {
    var soruSay by remember { mutableStateOf(0) }
    LaunchedEffect(etutVM.etutListDersMap, dersMap.value){
        var soru = 0
        etutVM.etutListDersMap[dersMap.key]?.forEach {
            if (it.type == 0) {
                soru += it.soru
            }else if (it.type == 1){
                soru += it.puan.toInt()
            }
        }
        soruSay = soru
    }
    Column(modifier = Modifier.fillMaxWidth()) {
        Row(
            modifier = Modifier.fillMaxWidth()
                .cursor(Cursor.Pointer)
                .onClick {
                    if (expanded.value == dersMap.key) {
                        expanded.value = ""
                    } else {
                        expanded.value = dersMap.key
                    }
                },
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(
                attrs = Modifier
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(AppColors.TextColor.opacity(0.8f))
                    .toAttrs()
            ) {
                Text(dersMap.key.ifEmpty { "Ders belirtilmemiş" })
            }
            Spacer()

            Span(
                attrs = Modifier
                    .fontSize(13.px)
                    .fontWeight(if (soruSay > 0) FontWeight.Medium else FontWeight.Normal)
                    .color(AppColors.TextColor)
                    .toAttrs()
            ) {
                Text(soruSay.toString())
            }

            Box(
                modifier = FaaliyetDersChevStyle.toModifier(),
                contentAlignment = Alignment.Center
            ) {
                if (expanded.value == dersMap.key) {
                    FaChevronDown(
                        size = IconSize.SM,
                        modifier = Modifier.color(AppColors.DetailBlue)
                    )
                } else {
                    FaChevronRight(size = IconSize.SM, modifier = Modifier.color(AppColors.GrayDark))
                }

            }

        }

    }
}


@Composable
fun KaynakInfo(etutVM: EtutVM, ders: String, kaynak: DersKaynak, onDrag: () -> Unit) {
    var soruSay by remember { mutableStateOf(0) }
    LaunchedEffect(etutVM.etutListDersMap){
        var soru = 0
        etutVM.etutListDersMap[ders]?.filter {it.kaynak == kaynak.isim }?.forEach {
            if (it.type == 0) {
                soru += it.soru
            }else if (it.type == 1){
                soru += it.puan.toInt()
            }
        }
        soruSay = soru
    }
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(2.px)
            .backgroundColor(Colors.White)
            .borderRadius(r = 4.px)
            .margin(bottom = 3.px)
            .draggable(true)
            .onDragStart {
                onDrag()
            }
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .fillMaxWidth()
                    .toAttrs()
            ) {
                Text(kaynak.isim)
            }

            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(AppColors.TextColor)
                    .fontWeight(if (soruSay > 0) FontWeight.Medium else FontWeight.Normal)
                    .padding(right = 4.px)
                    .toAttrs()
            ) {
                Text(soruSay.toString())
            }


        }
    }
}

package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.ApiResponse
import com.kelimesoft.etutpro.network.LoginResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.utils.Funcs
import kotlinx.serialization.json.Json
import org.w3c.xhr.FormData


class LoginVM {
    var userName by mutableStateOf("")
    var userPass by mutableStateOf("")
    var errorText by mutableStateOf("")
    var userNameForgot by mutableStateOf("")

    var userForgotCaptcha by mutableStateOf("")
    var forgetCaptcha by mutableStateOf("")

    suspend fun loginUser(): FuncResult {
        if (!Funcs.checkForm("login-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        val form = FormData()
        form.append("name", userName)
        form.append("pass", userPass)

        RestApi.userLogin(form)?.let { res ->
            console.log("login:", res)
            Json.decodeFromString<LoginResponse>(res).let { ares ->
                if (ares.data != null) {
                    AppData.appUser = ares.data
                    AppStorage.setString(AppStorage.userUUID, AppData.appUser.uuid)
                    return FuncResult(true)
                } else if (ares.error != null) {
                    errorText = ares.error
                    FuncResult(false, errorText)

                }
            }
        }
        return FuncResult(false, errorText)

    }


    suspend fun registerNewUser(user: NewUser, pass2: String): FuncResult {
        if (!Funcs.checkForm("register-form")) {
            return FuncResult(false, "Eksik alanları doldurunuz!")
        }else if (user.pass.length < 5) {
            errorText = "Şifreniz en az 5 karakter ve rakamdan oluşmalı!"
            return FuncResult(false, errorText)
        }else if (user.pass != pass2) {
            errorText = "Şifreniz aynı değil"
            return FuncResult(false, errorText)
        }else if (user.role == 0 && user.snf == -1) {
            errorText = "Sınıfınızı seçiniz!"
            return FuncResult(false, errorText)
        }

        RestApi.registerUSer(user)?.let { res ->
            Json.decodeFromString<ApiResponse>(res).let { ares ->
                if (ares.data != null) {
                    //AppData.userUUID = ares.data
                    //AppStorage.setString(AppStorage.userUUID, ares.data)
                    return FuncResult(true)
                } else if (ares.error != null) {
                    errorText = ares.error
                    return FuncResult(false, errorText)
                }
            }
        }
        return FuncResult(false, errorText)
    }


    suspend fun requestPass(): Boolean {
        if (!Funcs.checkForm("forgot-form")) {
            return false
        }

        if (userForgotCaptcha != forgetCaptcha) {
            errorText = "Resimdeki yazıyı hatalı girdiniz!"
            getForgotCaptcha()
            return false
        }

        val form = FormData()
        form.append("u", userNameForgot)
        RestApi.forgotPass(form)?.let { res ->
            Json.decodeFromString<ApiResponse>(res).let { resp ->
                if (resp.data != null) {
                    return true
                } else if (resp.error != null) {
                    errorText = resp.error
                }
            }
        }
        return false
    }

    fun getForgotCaptcha() {
        var uniqueChar = ""
        val randomChar = "8910ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789"
        // Generate captcha for a length of 5 with random characters
        for (i in 0 until 3) {
            val randomIndex = randomChar.indices.random()
            uniqueChar += (randomChar[randomIndex])
        }
        forgetCaptcha = uniqueChar

    }

}
package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.AppData
import com.kelimesoft.etutpro.models.AppStorage
import com.kelimesoft.etutpro.models.FuncResult
import com.kelimesoft.etutpro.models.OgrenciInfo
import com.kelimesoft.etutpro.network.ApiResponse
import com.kelimesoft.etutpro.network.OgrenciListResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.utils.Funcs
import kotlinx.serialization.json.Json
import org.w3c.xhr.FormData


class OgrenciVM {
    var ogrenciListem: List<OgrenciInfo> by mutableStateOf(listOf())

    var selectedOgrenci: OgrenciInfo? by mutableStateOf(null)

    suspend fun getOgrenciList(force: Boolean = false) {
        if (ogrenciListem.isEmpty() || force) {
            RestApi.getOgrenciList()?.let { res ->
                Json.decodeFromString<OgrenciListResponse>(res).let { ores ->
                    if (ores.data != null) {
                        ogrenciListem = ores.data
                        val select = AppStorage.getString(AppStorage.selectedOgrenci)
                        ogrenciListem.find { it.uuid == select }?.let {
                            selectOgrenci(it)
                        }
                    }
                }
            }
        }

    }

    suspend fun addOgrenci(name: String, code: String): FuncResult {
        if (!Funcs.checkForm("ogrenci-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        var form = FormData()
        form.append("role", AppData.appUser.role.toString())
        form.append("name", name)
        form.append("code", code)
        RestApi.addNewOgrenci(form)?.let { res ->
            Json.decodeFromString<ApiResponse>(res).let { ares ->
                if (ares.data != null){
                    return FuncResult(true)
                }else if (ares.error != null){
                    return FuncResult(false, ares.error)
                }
            }
        }
        return FuncResult(false, "Bağlantı hatası")
    }


    fun selectOgrenci(ogrenci: OgrenciInfo) {
        AppData.ViewModel.etutVM.gunEtutList = listOf()
        AppData.ViewModel.etutVM.etutListMap = mapOf()
        AppData.ViewModel.etutVM.allEtutList = listOf()
        AppData.ViewModel.dersVM.dersList = listOf()
        selectedOgrenci = ogrenci
        AppStorage.setString(AppStorage.selectedOgrenci, ogrenci.uuid)

    }

}
package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.etut.AddNewEtut
import com.kelimesoft.etutpro.pages.etut.EtutDayBox
import com.kelimesoft.etutpro.pages.etut.EtutSearch
import com.kelimesoft.etutpro.pages.kaynak.EtutKaynakList
import com.kelimesoft.etutpro.styles.ChevronButtonStyle
import com.kelimesoft.etutpro.utils.dateToDbStr
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.margin
import com.varabyte.kobweb.compose.foundation.layout.*

import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

import org.w3c.dom.events.EventListener
import org.w3c.dom.events.KeyboardEvent
import kotlin.js.Date

@Page("/etut")
@Composable
fun EtutPage() {
    IfUserExist(Screens.Etut) {
        EtutList()
        /*
        if (AppData.appUser.role == EtutRole.Ogrenci.ordinal){
            EtutList()
        }else{
            Column(modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                NoContentYet("Öğretmen ve Veli modülleri üzerinde çalışıyoruz, Çok yakında kullanıma sunulacaktır")
                BSButton(text = "Çıkış", variant = ButtonVariant.DangerOutline){
                    AppData.logOut(context)
                }

            }
        }

         */

    }
}

@Composable
fun EtutList() {
    val etutVM = AppData.ViewModel.etutVM
    val dersVM = AppData.ViewModel.dersVM
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    val scope = rememberCoroutineScope()

    var selectedEtutDate: String by remember { mutableStateOf("") }

    var showAddOgrenci by remember { mutableStateOf(false) }

    var showAddEtut by remember { mutableStateOf(false) }

    var showReplanButton by remember { mutableStateOf(false) }

    var etutType: EtutType? = null
    var secilenEtut: EtutItem? = null

    var alertModel: AlertModel? by remember { mutableStateOf(null) }
    var noContent by remember { mutableStateOf(false) }
    var draggedEtut: EtutItem? by remember { mutableStateOf(null) }
    var draggedKaynak: DersKaynak? by remember { mutableStateOf(null) }

    var showKaynakMenu by remember {mutableStateOf(true)}

    var newEtutSaat by remember { mutableStateOf("") }

    val escListener = remember {
        EventListener {it as KeyboardEvent
            if (it.keyCode == 27){
                if (etutVM.copiedEtut != null
                        || etutVM.etutSelectForCopy != null){
                    etutVM.copiedEtut = null
                    etutVM.etutSelectForCopy = null
                }

            }
        }
    }

    fun saveDrag(it: EtutItem, tarih: String, saat: String) {
        if (it.tarih == tarih && it.saati == saat) {
            draggedEtut = null
            return
        }
        var uuid: String? = null
        if (AppData.appUser.role > 0 && ogrenciVM.selectedOgrenci != null) {
            uuid = ogrenciVM.selectedOgrenci!!.uuid
        }
        val newSaat = saat.ifEmpty { it.saati }
        scope.launch {
            val updatedEtutItem = it.copy(
                saati = newSaat,
                tarih = tarih,
                done = false
            )
            etutVM.editEtutWithDrag(updatedEtutItem, uuid)
        }
    }

    LaunchedEffect(etutVM.weekStartDate, ogrenciVM.selectedOgrenci) {
        showReplanButton = false
        var uuid: String? = null
        if (AppData.appUser.role > 0) {
            ogrenciVM.getOgrenciList()
            if (ogrenciVM.selectedOgrenci != null) {
                uuid = ogrenciVM.selectedOgrenci!!.uuid
            }
        }
        if (dersVM.dersList.isEmpty()) {
            dersVM.getDersList(uuid)
        }
        etutVM.getEtutList(uuid)
        if (etutVM.allEtutList.isEmpty()) {
            noContent = true
        }else{
            noContent = false
            val notDoneCount = etutVM.allEtutList.filter { !it.done }.size
            if (notDoneCount > 0){
                val today = Date()
                if (etutVM.weekDays[6] == today.dateToDbStr() && today.getDay() == 0 && today.getHours() > 19){
                    showReplanButton = true
                }

                console.log("launch-effect: ", today.dateToDbStr(), etutVM.weekDays[6], notDoneCount)
            }
        }
        document.addEventListener(type = "keydown", escListener)

        //console.log("hourofday:", Date().getHours())
    }

    DisposableEffect(true){
        onDispose {
            document.removeEventListener("keydown", escListener)
        }
    }

    PageBox {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .maxWidth(1920.px)
                .backgroundColor(AppColors.ViewWhite)
                .borderRadius(r = 20.px)
                .overflow(Overflow.Hidden),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            MyNavBar(page = Screens.Etut, onSelect = {
                scope.launch {
                    noContent = false
                    etutVM.getEtutList(ogrenciVM.selectedOgrenci!!.uuid)
                    if (etutVM.allEtutList.isEmpty()) {
                        noContent = true
                    }
                }
            }, addOgrenci = {
                showAddOgrenci = true
        })
            //Row(modifier = Modifier.fillMaxWidth()) {
            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically) {
                Column(modifier = Modifier
                    .margin(top = 4.px, left = 3.px, bottom = 2.px)
                    .padding(top = 10.px, left = 4.px)
                    .backgroundColor(Colors.White)
                    .borderRadius(4.px)
                    .color(if (showKaynakMenu) AppColors.NumBlue else AppColors.TextColor)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        showKaynakMenu = !showKaynakMenu
                    },
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    FaBook(modifier = Modifier
                        .margin(bottom = 6.px)
                        , size = IconSize.LG)
                    SpanText("Kaynaklar", modifier = Modifier.fontSize(9.px))
                }

                Box(
                    modifier = ChevronButtonStyle.toModifier()
                        .onClick {
                            scope.launch {
                                etutVM.gotoPreviousWeek()
                            }

                        },
                    contentAlignment = Alignment.Center
                ) {
                    FaChevronLeft(size = IconSize.XL, modifier = Modifier.color(AppColors.TextColor))
                }
                SpanText("Önceki", modifier = Modifier.color(AppColors.TextColor))
                Spacer()
                EtutSearch()
                Spacer()

                if (showReplanButton){
                    Box(modifier = Modifier.padding(leftRight = 6.px)) {
                        BSButton(
                            modifier = Modifier
                                .fontSize(12.px)
                                .color(Colors.White)
                                .maxWidth(170.px),
                            text = "Kalanları Haftaya Planla",
                            size = ButtonSize.Small,
                            variant = ButtonVariant.Danger
                        ){
                            scope.launch {
                                var res = etutVM.replanUnfinished()
                                res?.let {
                                    alertModel = AlertModel(it, AlertIcon.Warning, AlertStyle.Warning)
                                    delay(3000)
                                    alertModel = null
                                }
                            }
                        }
                    }

                }


                SpanText("Sonraki", modifier = Modifier.color(AppColors.TextColor))
                Box(
                    modifier = ChevronButtonStyle.toModifier()
                        .onClick {
                            scope.launch {
                                etutVM.gotoNextWeek()
                            }
                        },
                    contentAlignment = Alignment.Center
                ) {
                    FaChevronRight(size = IconSize.XL, modifier = Modifier.color(AppColors.TextColor))
                }
            }


            Row(modifier = Modifier.fillMaxSize()
                .overflow(Overflow.Hidden),
            ) {
                if (showKaynakMenu) {
                    EtutKaynakList(etutVM) {
                        draggedEtut = null
                        draggedKaynak = it
                    }
                }
                Column(
                    modifier = Modifier.fillMaxSize()
                        .weight(1f)
                        .thenIf(
                            etutVM.copiedEtut != null
                                    || etutVM.etutSelectForCopy != null
                        ) {
                            //eğer kopyalama için seçilen item varsa boş yere tıklayınca iptal edilsin
                            Modifier.onClick {
                                etutVM.copiedEtut = null
                                etutVM.etutSelectForCopy = null
                            }

                        }
                        .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    SimpleGrid(
                        modifier = Modifier.fillMaxWidth(),

                        numColumns = numColumns(base = 1, sm = 2, md = 3, lg = 5)
                    ) {
                        etutVM.weekDays.forEachIndexed { index, tarih ->
                            EtutDayBox(etutVM, scope, index, tarih,
                                onAdd = { typ ->
                                    etutType = typ
                                    secilenEtut = null
                                    draggedKaynak = null
                                    selectedEtutDate = tarih
                                    if (AppData.appUser.role > 0 && ogrenciVM.selectedOgrenci == null) {
                                        scope.launch {
                                            alertModel = AlertModel("Öğreci Seçiniz!", AlertIcon.Warning, AlertStyle.Warning)
                                            delay(3000)
                                            alertModel = null
                                        }

                                    } else {
                                        showAddEtut = true
                                    }

                                }, onEdit = { etut ->
                                    etutType = null
                                    selectedEtutDate = etut.tarih
                                    secilenEtut = etut
                                    draggedKaynak = null
                                    showAddEtut = true
                                }, onDrag = {
                                    draggedKaynak = null
                                    draggedEtut = it
                                }, onDrop = { newSaat ->
                                    if (draggedEtut != null){
                                        newEtutSaat = newSaat
                                        saveDrag(draggedEtut!!, tarih, newSaat)
                                        draggedEtut = null
                                    }else if (draggedKaynak != null){
                                        etutType = null
                                        secilenEtut = null
                                        selectedEtutDate = tarih
                                        newEtutSaat = newSaat
                                        showAddEtut = true
                                    }
                                })
                        }
                    }
                }
            }

        }

        if (noContent) {
            if (AppData.appUser.role == EtutRole.Ogrenci.ordinal) {
                NoContentYet("Henüz etüt planı eklemedin, Etüt ve Ödevler  eklemek Gün ismindeki + butonuna tıklayabilirsin.")
            } else {
                if (ogrenciVM.ogrenciListem.isEmpty()){
                    Column(modifier = Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
                        NoContentYet("Ders ve Etüt takibi için öğrencinizi ekleyiniz!")
                        BSButton(
                            text = "Öğrenci Ekle",
                            variant = ButtonVariant.Success
                        ) {
                            showAddOgrenci = true
                        }
                    }
                }else if (ogrenciVM.selectedOgrenci != null) {
                    NoContentYet("Seçilen hafta için henüz etüt planı mevcut değil, Etüt ve Ödevler  eklemek Gün ismindeki + butonuna tıklayınız.")
                } else {
                    NoContentYet("Öğrenci Seçiniz!")
                }

            }
        }

        if (showAddEtut) {
            DialogView {
                AddNewEtut(
                    typ = etutType,
                    etut = secilenEtut,
                    kaynak = draggedKaynak,
                    date = selectedEtutDate,
                    saat = newEtutSaat,
                    onClose = {
                        showAddEtut = false
                    }, onSave = { etutItem, docs,  uuids ->
                        scope.launch {
                            var uuid: String? = null
                            if (AppData.appUser.role > 0) {
                                ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                            }
                            val res = if (etutItem.id > 0) {
                                etutVM.editExistingEtut(etutItem, uuid)
                            } else {
                                var docList = mutableListOf<String>()
                                docs.sortedBy { it.order }.forEach {
                                    console.log(it.toString())
                                    docList.add(it.name)
                                }
                                etutVM.addNewEtut(etutItem, uuid, docList, uuids)
                            }
                            if (res.result) {
                                showAddEtut = false
                                alertModel = AlertModel("Yeni etüt eklendi", AlertIcon.Checkmark, AlertStyle.Success)
                                delay(3000)
                                alertModel = null
                            } else {
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    })
            }
        }

        if (showAddOgrenci) {
            DialogView {
                AddOgrenci (onClose = {
                    showAddOgrenci = false
                }, onAdded = {
                    scope.launch {
                        showAddOgrenci = false
                        alertModel = AlertModel("Yeni Öğrenci Eklendi",
                            AlertIcon.Checkmark, AlertStyle.Success)
                        delay(3000)
                        alertModel = null
                    }
                })
            }
        }

        if (alertModel != null) {
            BSAlert(
                modifier = Modifier.zIndex(99),
                message = alertModel!!.message,
                dismissible = false,
                icon = alertModel!!.icon,
                style = alertModel!!.style
            )
        }
    }

}











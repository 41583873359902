package com.kelimesoft.etutpro.pages.kaynak

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.BSNumberInput
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.MyBSSelect
import com.kelimesoft.etutpro.components.VDivider
import com.kelimesoft.etutpro.models.*
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSCheckbox
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text


@Composable
fun AddNewKaynak(
    ders: String = "",
    onClose: () -> Unit,
    onAdded: () -> Unit
) {
    val kaynakVm = AppData.ViewModel.kaynakVM
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    var kaynadAd by remember { mutableStateOf("") }
    var sayfaSay by remember { mutableStateOf(0) }
    var uuid: String? by remember { mutableStateOf(null) }
    var dersler: List<String> by remember { mutableStateOf(listOf()) }
    var dersSelected by remember { mutableStateOf("") }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    val uuidList: MutableSet<String> by remember { mutableStateOf(mutableSetOf()) }

    val scope = rememberCoroutineScope()

    suspend fun createDersList() {
        val dlist: List<String>
        val dersVm = AppData.ViewModel.dersVM
        if (dersVm.dersList.isEmpty()) {
            dersVm.getDersList(uuid)
            dlist = dersVm.dersColorSet
                .map { it.dersad }.toList().sortedBy { it }
        } else {
            dlist = dersVm.dersColorSet
                .map { it.dersad }.toList().sortedBy { it }
        }
        dersler = dlist
    }

    LaunchedEffect(ders, ogrenciVM.selectedOgrenci) {
        if (AppData.appUser.role > 0) {
            AppData.ViewModel.ogrenciVM.getOgrenciList()
            AppData.ViewModel.ogrenciVM.selectedOgrenci?.let {
                uuid = it.uuid
            }
        }
        if (ders.isEmpty()){
            createDersList()
        }else{
            dersSelected = ders
        }
    }


    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {
        DialogTitle("Kaynak Ekle", onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("kaynak-form")
                .toAttrs()
        ) {

            if (ders.isEmpty()){
                MyBSSelect(
                    modifier = Modifier
                        .attrsModifier {
                            attr("value", ders)
                        }
                        .fillMaxWidth(),
                    id = "ders-select",
                    items = dersler,
                    placeholder = "Ders Seçiniz",
                    onItemSelect = { idx, text ->
                        if (idx == -1){
                            dersSelected = ""
                        }else{
                            try {
                                dersler.find { it == text }?.let {
                                    dersSelected = it
                                }
                            } catch (_: Exception) {
                                dersSelected = ""
                            }
                        }
                    }
                )
            }else{
                SpanText("Seçilen Ders: $dersSelected", modifier = Modifier
                    .fontWeight(FontWeight.Medium)
                    .fontSize(15.px)
                    .color(AppColors.DarkRed.opacity(0.8f))
                    .margin(bottom = 4.px)
                )
            }
            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Text,
                value = kaynadAd,
                placeholder = "Kaynak Adını giriniz",
                required = true,
                onValueChange = {
                    kaynadAd = it
                }
            )

            Row(modifier = Modifier.fillMaxWidth()
                .margin(bottom = 10.px),
                verticalAlignment = Alignment.CenterVertically) {
                SpanText("Toplam sayfa sayısı:",
                    modifier = Modifier.fillMaxWidth()
                        .color(AppColors.TextColor)
                        .fontSize(14.px)

                )
                BSNumberInput(
                    type = InputType.Number,
                    value = sayfaSay.toString(),
                    placeholder = "Toplam Sayfa Sayısı",
                    required = true,
                    onValueChange = {
                        sayfaSay = it
                    }
                )
            }

            Row(horizontalArrangement = Arrangement.SpaceBetween) {
                BSButton(
                    text = "Kapat",
                    variant = ButtonVariant.DangerOutline,
                    onClick = {
                        onClose()
                    }
                )
                BSButton(
                    text = "Ekle",
                    variant = ButtonVariant.Success,
                    onClick = {
                        val kaynak = DersKaynak(ders = ders, isim = kaynadAd, sayfa = sayfaSay)
                        scope.launch {
                            val res = kaynakVm.addNewKaynak(kaynak, uuid, uuidList.toList())
                            if (res.result){
                                onAdded()
                            }else{
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }

                    }
                )
            }


        }
        if (AppData.appUser.role > 0
            && AppData.ViewModel.ogrenciVM.ogrenciListem.isNotEmpty()
        ) {
            fun getSnf(ogr: OgrenciInfo): String {
                if (ogr.snf == 13) return " (Mezun)"
                else if (ogr.snf == 20) return " (Ünv.)"
                else return " (${ogr.snf}.Snf)"
            }
            VDivider(modifier = Modifier.margin(topBottom = 1.px))
            Span(
                attrs = Modifier.color(AppColors.DarkRed)
                    .margin(bottom = 2.px)
                    .fontSize(11.px).toAttrs()
            ) {
                Text("Diğer öğrencilerinize de aynı görevi ekleyebilirsiniz.")
            }

            SimpleGrid(numColumns(base = 4), modifier = Modifier.fillMaxWidth()) {
                AppData.ViewModel.ogrenciVM.ogrenciListem
                    .filter { it.uuid != AppData.ViewModel.ogrenciVM.selectedOgrenci?.uuid }
                    .sortedBy { it.snf }
                    .forEach { ogr ->
                        Box(modifier = Modifier.margin(right = 2.px)) {
                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .backgroundColor(AppColors.Gray.opacity(0.4f))
                                    .borderRadius(r = 4.px)
                                    .margin(right = 2.px),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                BSCheckbox(
                                    modifier = Modifier
                                        .margin(0.px)
                                        .color(AppColors.TextColor),
                                    defaultChecked = false,
                                    label = "",
                                    onClick = {
                                        if (it) {
                                            uuidList.add(ogr.uuid)
                                        } else {
                                            uuidList.remove(ogr.uuid)
                                        }
                                    }
                                )
                                Span(attrs = Modifier.fontSize(13.px).toAttrs()) {
                                    Text(ogr.name)
                                    Span(attrs = Modifier.fontSize(10.px).toAttrs()) { Text(getSnf(ogr)) }
                                }
                            }
                        }
                    }

            }
        }
    }


    if (alertModel != null) {
        BSAlert(
            modifier = Modifier.zIndex(99),
            message = alertModel!!.message,
            dismissible = false,
            icon = alertModel!!.icon,
            style = alertModel!!.style

        )
    }

}
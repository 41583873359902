package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.PageBox
import com.kelimesoft.etutpro.components.VDivider
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.AppData
import com.kelimesoft.etutpro.models.DersKaynak
import com.kelimesoft.etutpro.models.opacity
import com.kelimesoft.etutpro.viewmodels.KaynakVM
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Iframe

@OptIn(ExperimentalComposeWebApi::class)
@Page("/kaynak")
@Composable
fun Kaynak() {
    val kaynakVM = AppData.ViewModel.kaynakVM
    var kaynakList by remember { mutableStateOf(listOf<DersKaynak>()) }
    LaunchedEffect(true){
        if (kaynakVM.allKaynakList.isEmpty()) {
            val ogrenciVM = AppData.ViewModel.ogrenciVM
            var uuid: String? = null
            if (AppData.appUser.role > 0) {
                ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
            }
            kaynakVM.getKaynakList(uuid)
            kaynakList = kaynakVM.allKaynakList
        }
    }


    PageBox {
        Row(modifier = Modifier.fillMaxSize()) {
            Column(modifier = Modifier
                .fillMaxHeight()
                .backgroundColor(AppColors.ViewWhite.opacity(0.7f))
                .padding(4.px)
                .width(300.px)) {
                kaynakList.forEach { kaynak ->
                    Box(modifier = Modifier.fillMaxWidth()
                        .padding(8.px)
                        .backgroundColor(AppColors.ViewWhite)
                        .borderRadius(r = 6.px)
                        .margin(bottom = 6.px)
                        /*
                        .onClick {
                            kaynakLink = ""
                            scope.launch {
                                delay(300)
                                //kaynakLink = kaynak.link
                            }
                        }*/
                    ) {
                        Column(modifier = Modifier.fillMaxWidth()
                            .color(AppColors.TextColor),
                            horizontalAlignment = Alignment.Start
                        ) {
                            Row {
                                SpanText(kaynak.ders, modifier = Modifier.fontSize(12.px))
                                //SpanText(", ")
                                //SpanText(kaynak.yayin, modifier = Modifier.fontSize(12.px))
                            }
                            SpanText(kaynak.isim, modifier = Modifier.fontSize(16.px).fontWeight(FontWeight.SemiBold))
                        }
                    }



                }
            }
            /*
            Box(modifier = Modifier.fillMaxSize()) {
                if (kaynakLink.isNotEmpty()) {
                    Iframe(
                        attrs = Modifier
                            .fillMaxSize()
                            .toAttrs {
                                attr("src", kaynakLink)
                            }) {
                    }
                }
            }
            */

        }

    }

}


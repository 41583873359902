package com.kelimesoft.etutpro.components

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.models.AppColors
import com.stevdza.san.kotlinbs.models.InputSize
import com.stevdza.san.kotlinbs.models.InputValidation
import com.stevdza.san.kotlinbs.models.SelectSize
import com.stevdza.san.kotlinbs.util.UniqueIdGenerator
import com.varabyte.kobweb.compose.css.disabled
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.classNames
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.*
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*

@Composable
fun BSNumberInput(
    modifier: Modifier = Modifier,
    id: String? = null,
    label: String? = null,
    placeholder: String? = null,
    type: InputType.Number = InputType.Number,
    size: InputSize = InputSize.Default,
    disabled: Boolean = false,
    readOnly: Boolean = false,
    plainText: Boolean = false,
    floating: Boolean = false,
    required: Boolean = false,
    validation: InputValidation = InputValidation(),
    value: String,
    onValueChange: (Int) -> Unit,
    onEnterClick: (() -> Unit)? = null
) {
    val randomId = remember {
        id ?: UniqueIdGenerator.generateUniqueId("input")
    }
    Div(
        attrs = modifier
            .thenIf(
                condition = floating,
                other = Modifier.classNames("form-floating")
            )
            .toAttrs()
    ) {
        if (!floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            )
            {
                Text(value = label)
            }
        }
        Input(
            attrs = Modifier
                .id(randomId)
                .classNames(if (plainText) "form-control-plaintext" else "form-control")
                .thenIf(
                    condition = validation.isValid,
                    other = Modifier.classNames("is-valid")
                )
                .thenIf(
                    condition = validation.isInvalid,
                    other = Modifier.classNames("is-invalid")
                )
                .thenIf(
                    condition = size != InputSize.Default,
                    other = Modifier.classNames(size.value)
                )
                .toAttrs {
                    value(value)
                    onInput { it.value?.let { it1 -> onValueChange(it1.toInt()) } }
                    onKeyDown {
                        if (onEnterClick != null) {
                            it.preventDefault()
                            if (it.key == "Enter") {
                                onEnterClick()
                            }
                        }
                    }
                    if (!placeholder.isNullOrEmpty()) attr("placeholder", placeholder)
                    if (disabled) disabled()
                    if (readOnly) readOnly()
                    if (required) required()
                },
            type = type
        )
        if (validation.isValid) {
            Div(
                attrs = Modifier
                    .classNames("valid-feedback")
                    .toAttrs()
            ) {
                Text(value = validation.validFeedback)
            }
        }
        if (validation.isInvalid) {
            Div(
                attrs = Modifier
                    .classNames("invalid-feedback")
                    .toAttrs()
            ) {
                Text(value = validation.invalidFeedback)
            }
        }
        if (floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            )
            {
                Text(value = label)
            }
        }
    }
}

@Composable
fun BSTimeInput(
    modifier: Modifier = Modifier,
    id: String? = null,
    label: String? = null,
    placeholder: String? = null,
    type: InputType.Time = InputType.Time,
    size: InputSize = InputSize.Default,
    disabled: Boolean = false,
    readOnly: Boolean = false,
    plainText: Boolean = false,
    floating: Boolean = false,
    required: Boolean = false,
    validation: InputValidation = InputValidation(),
    value: String, // The current value of the input (controlled by the parent)
    onValueChange: (String) -> Unit, // Callback when the input changes
    onEnterClick: (() -> Unit)? = null
) {
    val randomId = remember {
        id ?: UniqueIdGenerator.generateUniqueId("input")
    }

    // Synchronize the internal state with the passed-in `value`
    var time by remember { mutableStateOf(value) }
    LaunchedEffect(value) {
        time = value // Update internal state when `value` changes
    }

    Div(
        attrs = modifier
            .thenIf(
                condition = floating,
                other = Modifier.classNames("form-floating")
            )
            .toAttrs()
    ) {
        if (!floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            ) {
                Text(value = label)
            }
        }
        Input(
            attrs = Modifier
                .id(randomId)
                .classNames(if (plainText) "form-control-plaintext" else "form-control")
                .thenIf(
                    condition = validation.isValid,
                    other = Modifier.classNames("is-valid")
                )
                .thenIf(
                    condition = validation.isInvalid,
                    other = Modifier.classNames("is-invalid")
                )
                .thenIf(
                    condition = size != InputSize.Default,
                    other = Modifier.classNames(size.value)
                )
                .toAttrs {
                    value(time) // Use the internal state for the input value
                    onInput {
                        val newValue = it.value
                        time = newValue // Update internal state
                        onValueChange(newValue) // Call the provided onValueChange function
                    }
                    onKeyDown {
                        if (onEnterClick != null) {
                            it.preventDefault()
                            if (it.key == "Enter") {
                                onEnterClick()
                            }
                        }
                    }
                    if (!placeholder.isNullOrEmpty()) attr("placeholder", placeholder)
                    if (disabled) disabled()
                    if (readOnly) readOnly()
                    if (required) required()
                },
            type = type
        )
        if (validation.isValid) {
            Div(
                attrs = Modifier
                    .classNames("valid-feedback")
                    .toAttrs()
            ) {
                Text(value = validation.validFeedback)
            }
        }
        if (validation.isInvalid) {
            Div(
                attrs = Modifier
                    .classNames("invalid-feedback")
                    .toAttrs()
            ) {
                Text(value = validation.invalidFeedback)
            }
        }
        if (floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            ) {
                Text(value = label)
            }
        }
    }
}

@Composable
fun BookPageRangeInput(
    modifier: Modifier = Modifier,
    id: String? = null,
    label: String? = null,
    placeholder: String? = null,
    //type: InputType.Time = InputType.Time,
    size: InputSize = InputSize.Default,
    disabled: Boolean = false,
    readOnly: Boolean = false,
    plainText: Boolean = false,
    floating: Boolean = false,
    required: Boolean = false,
    validation: InputValidation = InputValidation(),
    value: String, // The current value of the input (controlled by the parent)
    onValueChange: (String) -> Unit, // Callback when the input changes
    onEnterClick: (() -> Unit)? = null
) {
    val randomId = remember {
        id ?: UniqueIdGenerator.generateUniqueId("input")
    }

    // Synchronize the internal state with the passed-in `value`
    var sayfalar by remember { mutableStateOf(value) }
    LaunchedEffect(value) {
        sayfalar = value // Update internal state when `value` changes
    }

    Div(
        attrs = modifier
            .thenIf(
                condition = floating,
                other = Modifier.classNames("form-floating")
            )
            .toAttrs()
    ) {
        if (!floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .margin(1.px)
                    .color(AppColors.Gray)
                    .toAttrs(),
                forId = randomId
            ) {
                Text(value = label)
            }
        }
        Input(
            attrs = Modifier
                .id(randomId)
                .classNames(if (plainText) "form-control-plaintext" else "form-control")
                .thenIf(
                    condition = validation.isValid,
                    other = Modifier.classNames("is-valid")
                )
                .thenIf(
                    condition = validation.isInvalid,
                    other = Modifier.classNames("is-invalid")
                )
                .thenIf(
                    condition = size != InputSize.Default,
                    other = Modifier.classNames(size.value)
                )
                .toAttrs {
                    value(sayfalar) // Use the internal state for the input value
                    onInput {
                        val newValue = it.value
                        sayfalar = newValue
                        //isValidRange = validatePageRange(newValue) // Validate the input on change
                        onValueChange(newValue) // Notify the parent of the change
                    }
                    onKeyDown {
                        if (onEnterClick != null) {
                            it.preventDefault()
                            if (it.key == "Enter") {
                                onEnterClick()
                            }
                        }
                    }
                    if (!placeholder.isNullOrEmpty()) attr("placeholder", placeholder)
                    if (disabled) disabled()
                    if (readOnly) readOnly()
                    if (required) required()
                },
            type = InputType.Text
        )
        if (validation.isValid) {
            Div(
                attrs = Modifier
                    .classNames("valid-feedback")
                    .toAttrs()
            ) {
                Text(value = validation.validFeedback)
            }
        }
        if (validation.isInvalid) {
            Div(
                attrs = Modifier
                    .classNames("invalid-feedback")
                    .toAttrs()
            ) {
                Text(value = validation.invalidFeedback)
            }
        }
        if (floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            ) {
                Text(value = label)
            }
        }
    }
}


/*
@Composable
fun BookPageRangeInput1(
    modifier: Modifier = Modifier,
    placeholder: String = "(sayfalar: 1-45)",
    size: InputSize = InputSize.Default,
    disabled: Boolean = false,
    readOnly: Boolean = false,
    plainText: Boolean = false,
    floating: Boolean = false,
    required: Boolean = false,
    value: String, // Current value of the input
    onValueChange: (String) -> Unit, // Callback when the value changes
    onEnterClick: (() -> Unit)? = null
) {
    var pageRange by remember { mutableStateOf(value) }
    var isValidRange by remember { mutableStateOf(true) }
    var errorMessage by remember { mutableStateOf("") }

    // Function to validate the page range input
    fun validatePageRange(input: String): Boolean {
        // Regex to match the format "number-number"
        val rangeRegex = """^\d+-\d+$""".toRegex()
        return if (rangeRegex.matches(input)) {
            val (start, end) = input.split("-").map { it.toInt() }
            if (start <= end) {
                true // Valid range
            } else {
                errorMessage = "Invalid range: Start page must be less than or equal to end page."
                false
            }
        } else {
            errorMessage = "Invalid format. Use format: 'start-end' (e.g., 1-45)."
            false
        }
    }

    LaunchedEffect(value) {
        pageRange = value
        //isValidRange = validatePageRange(value) // Validate the page range when the input changes
    }

    Div(
        attrs = modifier
            .thenIf(
                condition = floating,
                other = Modifier.classNames("form-floating")
            )
            .toAttrs()
    ) {
        if (!floating && label != null) {
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            ) {
                Text(value = label)
            }
        }
        Input(
            attrs = Modifier
                .classNames(if (isValidRange) "form-control" else "form-control is-invalid")
                .toAttrs {
                    value(pageRange)
                    onInput {
                        val newValue = it.value
                        pageRange = newValue
                        isValidRange = validatePageRange(newValue) // Validate the input on change
                        onValueChange(newValue) // Notify the parent of the change
                    }
                    onKeyDown {
                        if (onEnterClick != null) {
                            it.preventDefault()
                            if (it.key == "Enter") {
                                onEnterClick()
                            }
                        }
                    }
                    attr("placeholder", placeholder)
                },
            type = InputType.Text // This allows for freeform text input
        )

        // Display feedback if the input is invalid
        if (!isValidRange) {
            Div(
                attrs = Modifier
                    .classNames("invalid-feedback")
                    .toAttrs()
            ) {
                Text(value = errorMessage)
            }
        }
    }
}

*/


@Composable
fun MyBSSelect(
    modifier: Modifier = Modifier,
    id: String? = null,
    items: List<String>,
    placeholder: String? = null,
    size: SelectSize = SelectSize.Default,
    validation: InputValidation = InputValidation(),
    disabled: Boolean = false,
    floating: Boolean = false,
    floatingLabel: String = "Select",
    onItemSelect: (Int, String) -> Unit
) {
    val randomId = remember {
        id ?: UniqueIdGenerator.generateUniqueId("select")
    }
    if (floating) {
        Div(
            attrs = modifier
                .classNames("form-floating")
                .toAttrs()
        ) {
            MyBSSelectInternal(
                id = randomId,
                items = items,
                placeholder = placeholder,
                validation = validation,
                size = size,
                disabled = disabled,
                onItemSelect = onItemSelect
            )
            Label(
                attrs = Modifier
                    .classNames("form-label")
                    .toAttrs(),
                forId = randomId
            ) {
                Text(value = floatingLabel)
            }
        }
    } else {
        Div(attrs = modifier.toAttrs()) {
            MyBSSelectInternal(
                modifier = modifier,
                id = randomId,
                items = items,
                placeholder = placeholder,
                validation = validation,
                size = size,
                disabled = disabled,
                onItemSelect = onItemSelect
            )
        }
    }
}

@Composable
private fun MyBSSelectInternal(
    modifier: Modifier? = null,
    id: String,
    items: List<String>,
    placeholder: String?,
    validation: InputValidation,
    size: SelectSize,
    disabled: Boolean,
    onItemSelect: (Int, String) -> Unit
) {
    Select(
        attrs = Modifier
            .then(modifier ?: Modifier)
            .id(id)
            .classNames("form-select")
            .thenIf(
                condition = validation.isValid,
                other = Modifier.classNames("is-valid")
            )
            .thenIf(
                condition = validation.isInvalid,
                other = Modifier.classNames("is-invalid")
            )
            .thenIf(
                condition = size != SelectSize.Default,
                other = Modifier.classNames(size.value)
            )
            .toAttrs {
                if (disabled) disabled()
                onChange {
                    it.value?.let { text ->
                        if (text != placeholder) {
                            onItemSelect(items.indexOf(text), text)
                        }else{
                            onItemSelect(-1, "")
                        }
                    }
                }
            }
    ) {
        if (!placeholder.isNullOrEmpty()) {
            Option(
                attrs = Modifier.toAttrs { selected() },
                value = placeholder
            ) {
                Text(placeholder)
            }
        }
        items.forEach { text ->
            Option(value = text) {
                Text(value = text)
            }
        }
    }
    if (validation.isValid) {
        Div(
            attrs = Modifier
                .classNames("valid-feedback")
                .toAttrs()
        ) {
            Text(value = validation.validFeedback)
        }
    }
    if (validation.isInvalid) {
        Div(
            attrs = Modifier
                .classNames("invalid-feedback")
                .toAttrs()
        ) {
            Text(value = validation.invalidFeedback)
        }
    }
}
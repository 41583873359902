package com.kelimesoft.etutpro.pages.register

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.DialogTitle
import com.kelimesoft.etutpro.components.FaInfoCircle
import com.kelimesoft.etutpro.components.RoleButton
import com.kelimesoft.etutpro.models.*
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.components.BSTooltip
import com.stevdza.san.kotlinbs.components.initializeTooltips
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.forms.BSSelect
import com.stevdza.san.kotlinbs.models.TooltipDirection
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form

@Composable
fun RegisterNewUSer(
    onClose: () -> Unit,
    onRegister: (NewUser, String) -> Unit
) {

    var regRole by remember { mutableStateOf(EtutRole.Ogrenci) }
    var regUser by remember { mutableStateOf("") }
    var regEposta by remember { mutableStateOf("") }
    var regPass1 by remember { mutableStateOf("") }
    var regPass2 by remember { mutableStateOf("") }
    var regSnf by remember { mutableStateOf(-1) }

    LaunchedEffect(Unit) {
        initializeTooltips()
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {

        DialogTitle("Yeni Kayıt: ${regRole.text}", onClose = onClose)

        Form(attrs = Modifier
            .fillMaxWidth()
            .padding(topBottom = 8.px, leftRight = 4.px)
            .id("register-form")
            .toAttrs()
        ) {

            Row (modifier = Modifier.fillMaxWidth()
                .margin(right = 10.px),
                horizontalArrangement = Arrangement.Center
            ){
                RoleButton(EtutRole.Ogrenci, regRole == EtutRole.Ogrenci){
                    regRole = EtutRole.Ogrenci
                }
                RoleButton(EtutRole.Ogretmen, regRole == EtutRole.Ogretmen){
                    regRole = EtutRole.Ogretmen
                }
                RoleButton(EtutRole.Veli, regRole == EtutRole.Veli){
                    regRole = EtutRole.Veli
                }

            }


            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically) {
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 14.px),
                    type = InputType.Text,
                    value = regUser,
                    placeholder = "${regRole.text} Adı",
                    required = true,
                    onValueChange = {
                        regUser = it.replace(" ", "")
                    }
                )

                Row(modifier = Modifier
                    .margin(left = (-48).px, top = (-8).px),
                    verticalAlignment = Alignment.CenterVertically) {
                    BSTooltip("Adınızı boşluk bırakmadan benzersiz bir Takma Ad olarak giriniz",
                        direction = TooltipDirection.Top){
                        FaInfoCircle(size = IconSize.LG,
                            modifier = Modifier
                                .margin(right = 6.px)
                                .color(AppColors.TextColor.opacity(0.7f)))

                    }

                    FaUser(
                        size = IconSize.SM,
                        style = IconStyle.FILLED,
                        modifier = Modifier.color(AppColors.TextColor)
                    )
                }

            }

            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically) {
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 14.px),
                    type = InputType.Email,
                    value = regEposta,
                    placeholder = if (regRole == EtutRole.Ogrenci) "E-posta Adresi (zorunlu değil)" else "E-posta Adresiniz",
                    required = regRole != EtutRole.Ogrenci,
                    onValueChange = {
                        regEposta = it
                    }
                )
                FaEnvelope(
                    size = IconSize.SM,
                    style = IconStyle.FILLED,
                    modifier = Modifier.color(AppColors.TextColor)
                        .margin(left = (-24).px, top = (-8).px)
                )
            }

            if (regRole == EtutRole.Ogrenci){
                BSSelect(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 14.px),
                    items = OgrenciSnf.snfNames(),

                    placeholder = "Sınıfınızı Seçiniz",
                    onItemSelect = {idx, text ->
                        try {
                            OgrenciSnf.entries.find { it.text == text }?.let {
                                regSnf = it.snf
                            }
                        }catch (_:Exception){}
                        //console.log(regSnf)
                    }
                )
            }



            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically) {

            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Password,
                value = regPass1,
                placeholder = "Şifre",
                required = true,
                onValueChange = {
                    regPass1 = it
                }
            )
                FaEyeSlash(
                    size = IconSize.SM,
                    style = IconStyle.FILLED,
                    modifier = Modifier.color(AppColors.TextColor)
                        .margin(left = (-24).px, top = (-8).px)
                )
            }

            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically) {

            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Password,
                value = regPass2,
                placeholder = "Şifre tekrar",
                required = true,
                onValueChange = {
                    regPass2 = it
                }
            )
                FaEyeSlash(
                    size = IconSize.SM,
                    style = IconStyle.FILLED,
                    modifier = Modifier.color(AppColors.TextColor)
                        .margin(left = (-24).px, top = (-8).px)
                )
            }

            Row(horizontalArrangement = Arrangement.SpaceBetween) {
                BSButton(
                    text = "Kapat",
                    variant = ButtonVariant.DangerOutline,
                    onClick = {
                        onClose()
                    }
                )
                BSButton(
                    text = "Gönder",
                    variant = ButtonVariant.Success,
                    onClick = {
                        val user = NewUser(regUser, regEposta, regPass1, regSnf, regRole.ordinal)

                        onRegister(user, regPass2)
                    }
                )
            }
        }
    }
}
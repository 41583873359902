package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.styles.RowButtonStyle
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.BoxScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.components.style.toModifier

@Composable
fun RowButton(modifier: Modifier = Modifier, content: @Composable BoxScope.() -> Unit ) {
    Box(modifier = RowButtonStyle.toModifier().then(modifier),
        contentAlignment = Alignment.Center
    ) {
        content()
    }
}
package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize

@Composable
fun FaSignOut(modifier: Modifier = Modifier, size: IconSize? = null) = FaIcon("sign-out", modifier, IconCategory.SOLID, size)

@Composable
fun FaSave(modifier: Modifier = Modifier, size: IconSize? = null) = FaIcon("save", modifier, IconCategory.REGULAR, size)

@Composable
fun FaEdit(modifier: Modifier = Modifier, size: IconSize? = null) = FaIcon("edit", modifier, IconCategory.REGULAR, size)

@Composable
fun FaRefresh(modifier: Modifier = Modifier, size: IconSize? = null) = FaIcon("refresh", modifier, IconCategory.SOLID, size)

@Composable
fun FaInfoCircle(modifier: Modifier = Modifier, size: IconSize? = null) = FaIcon("info-circle", modifier, IconCategory.SOLID, size)


@Composable
fun MyFaIcon(modifier: Modifier = Modifier, icon: String,
             category: IconCategory = IconCategory.SOLID, size: IconSize = IconSize.SM) {
    FaIcon(icon, modifier,  category, size)
}
package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.AppColors
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div

@Composable
fun DropDownMenu(
    menuModifier: Modifier = Modifier,
    butonModifier: Modifier = Modifier,
    rightAlign: Boolean = false,
    content: @Composable () -> Unit,
    id: String,
    buttons: @Composable ColumnScope.() -> Unit
) {
    Div(
        attrs = menuModifier
            .position(Position.Relative)
            .display(DisplayStyle.InlineBlock)
            .toAttrs()
    ) {
        Box (
            modifier = butonModifier,
            contentAlignment = Alignment.Center
        ) {
            content()
        }

        Div(
            attrs = Modifier
                .id(id)
                .classNames("app-menu")
                .display(DisplayStyle.None)
                .position(Position.Absolute)
                .styleModifier {
                    if (rightAlign){
                        property("right", "0")
                    }
                }
                //.right(0.px)
                .backgroundColor(AppColors.DropMenu)
                .borderRadius(r = 6.px)
                .boxShadow(0.px, 8.px, 16.px, 0.px, Color.rgba(0, 0, 0, 0.2f))
                .minWidth(120.px)
                .maxWidth(200.px)
                .padding(10.px)
                .zIndex(9)
                .toAttrs()
        ) {
            Column {
                buttons()
            }

        }
    }
}


@Composable
fun SearchDropDownMenu(
    menuModifier: Modifier = Modifier,
    butonModifier: Modifier = Modifier,
    rightAlign: Boolean = false,
    content: @Composable () -> Unit,
    id: String,
    buttons: @Composable ColumnScope.() -> Unit
) {
    Div(
        attrs = menuModifier
            .position(Position.Relative)
            .display(DisplayStyle.InlineBlock)
            .toAttrs()
    ) {
        Box (
            modifier = butonModifier,
            contentAlignment = Alignment.Center
        ) {
            content()
        }

        Div(
            attrs = Modifier
                .id(id)
                .classNames("app-menu")
                .display(DisplayStyle.None)
                .position(Position.Absolute)
                .styleModifier {

                        property("right", "0")
                    //property("left", "0")

                }
                //.right(0.px)
                .backgroundColor(AppColors.DropMenu)
                .borderRadius(r = 6.px)
                .boxShadow(0.px, 8.px, 16.px, 0.px, Color.rgba(0, 0, 0, 0.2f))
                .minWidth(200.px)
                .maxWidth(500.px)
                .padding(10.px)
                .zIndex(9)
                .toAttrs()
        ) {
            Column {
                buttons()
            }

        }


    }
}
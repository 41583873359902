package com.kelimesoft.etutpro.models

enum class WeekDays(
    val ad: String,
    val day: Int
) {
    Pazartesi("Pazartesi",1),
    Sali("Salı",2),
    Carsamba("Çarşamba",3),
    Persembe("Perşembe",4),
    Cuma("Cuma",5),
    Cumartesi("Cumartesi",6),
    Pazar("Pazar",7);


    companion object {
        val haftaGunleri: List<WeekDays>
            get() = listOf(Pazartesi, Sali, Carsamba, Persembe, Cuma)

    }


}
package com.kelimesoft.etutpro.pages.etut

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.kelimesoft.etutpro.components.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.ImageUploadResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.pages.ders.DersGunuSaati
import com.kelimesoft.etutpro.pages.kaynak.AddNewKaynak
import com.kelimesoft.etutpro.styles.DropDownStyle
import com.kelimesoft.etutpro.styles.DropMenuItemStyle
import com.kelimesoft.etutpro.styles.RoleButtonStyle
import com.kelimesoft.etutpro.utils.Funcs.closeDropDowns
import com.kelimesoft.etutpro.utils.toBase64
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSCheckbox
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaCaretDown
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.*
import org.w3c.files.File
import org.w3c.files.FileReader
import org.w3c.files.get


data class DocEtut(
    var name: String,
    val page: Int = 1,
    var order: Int = 0
    )


@Composable
fun AddNewEtut(
    typ: EtutType? = null,
    etut: EtutItem? = null,
    kaynak: DersKaynak? = null,
    date: String,
    saat: String,
    onClose: () -> Unit,
    onSave: (EtutItem, List<DocEtut>, List<String>) -> Unit
) {
    val pageLimit = if (AppData.appUser.name == "baba8") 20 else 10
    var numberOfDocs = 0

    val scope = rememberCoroutineScope()
    val dersVm = AppData.ViewModel.dersVM
    val kaynakVm = AppData.ViewModel.kaynakVM

    var dersler: List<String> by remember { mutableStateOf(listOf()) }
    var kaynakList: List<DersKaynak> by remember { mutableStateOf(listOf()) }

    var ders by remember { mutableStateOf("") }
    var konu by remember { mutableStateOf("") }
    var soru by remember { mutableStateOf(0) }
    var puan by remember { mutableStateOf(0.0) }
    var type by remember { mutableStateOf(EtutType.KonuTest) }
    var link by remember { mutableStateOf("") }
    var dersKaynak by remember { mutableStateOf("") }

    var sayfalar by remember { mutableStateOf("") }
    var saati by remember { mutableStateOf("08:30") }

    var docLinks by remember { mutableStateOf(listOf<DocEtut>()) }
    var doctype: DocType? by remember { mutableStateOf(null) }

    val docPageCount: Int = docLinks.sumOf { it.page }

    val uuidList: MutableSet<String> by remember { mutableStateOf(mutableSetOf()) }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    var showAddKaynak by remember { mutableStateOf(false) }

    var disableDers by remember { mutableStateOf(false) }
    var disableKaynak by remember { mutableStateOf(false) }


    suspend fun createDersList() {
        var dlist = listOf<String>()
        if (dersVm.dersList.isEmpty()) {
            val ogrenciVM = AppData.ViewModel.ogrenciVM
            var uuid: String? = null
            if (AppData.appUser.role > 0) {
                ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
            }
            dersVm.getDersList(uuid)
            dlist = dersVm.dersColorSet
                .map { it.dersad }.toList().sortedBy { it }
        } else {
            dlist = dersVm.dersColorSet
                .map { it.dersad }.toList().sortedBy { it }
        }
        dersler = dlist
    }

    suspend fun createKaynakList() {
        if (kaynakVm.allKaynakList.isEmpty()) {
            val ogrenciVM = AppData.ViewModel.ogrenciVM
            var uuid: String? = null
            if (AppData.appUser.role > 0) {
                ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
            }
            kaynakVm.getKaynakList(uuid)
        }
    }

    fun findEtutStartTime(){
        saati = AppData.ViewModel.etutVM.findEtutStartTime(date)
    }

    LaunchedEffect(true) {
        createDersList()
        createKaynakList()
        if (typ != null) {
            type = typ
            findEtutStartTime()
            disableDers = false
            disableKaynak = false
        } else if (etut != null) {
            ders = etut.ders
            konu = etut.konu
            soru = etut.soru
            dersKaynak = etut.kaynak
            link = etut.link
            puan = etut.puan
            saati = etut.saati
            sayfalar = etut.sayfa
            EtutType.entries.find { it.type == etut.type }?.let {
                type = it
            }
            if (etut.type < 2) {
                //konu testi olacak
                //view yerleşsin diye bekliyorum
                delay(200)
                try {
                    (document.getElementById("ders-select") as HTMLSelectElement).value = ders
                } catch (_: Exception) {
                }
                doctype = DocType.Link
            }
            delay(200)
            disableDers = true
            disableKaynak = true
        }else if (kaynak != null){
            console.log("from-kaynak:", kaynak)
            saati = saat
            ders = kaynak.ders
            dersKaynak = kaynak.isim
            //findEtutStartTime()
            delay(200)
            try {
                (document.getElementById("ders-select") as HTMLSelectElement).value = ders
            } catch (_: Exception) {
            }
            delay(200)
            disableDers = true
            disableKaynak = true
        }
    }

    fun validateTime(input: String): Boolean {
        // Check if the time is in HH:mm format (24-hour)
        val timeRegex = """^([01]\d|2[0-3]):([0-5]\d)$""".toRegex()
        return if (timeRegex.matches(input)) {
            true // Valid time
        } else {
            false // Invalid time
        }
    }


    fun saveEtut() {
        var etutDate = date
        var etutId = 0L
        if (etut != null) {
            etutDate = etut.tarih
            etutId = etut.id
        }
        var doclinks = docLinks
        var etutlink = link
        if (doctype == DocType.Link) {
            doclinks = listOf()
        } else {
            etutlink = ""
        }
        val etutItem =
            EtutItem(
                etutId,
                ders,
                saati,
                konu,
                dersKaynak,
                soru,
                puan,
                0,0,
                type.ordinal,
                etutlink,
                sayfalar,
                etutDate,
                AppData.appUser.role,
                false
            )
        onSave(etutItem, doclinks, uuidList.toList())
    }

    fun resizeAndUploadImage(file: File, doc: DocEtut) {
        val maxSize = 1260
        val reader = FileReader()
        reader.onload = { readerEvent ->
            val image = Image()
            image.onload = {
                // Resize the image
                val canvas = document.createElement("canvas") as HTMLCanvasElement
                val context = canvas.getContext("2d") as? CanvasRenderingContext2D
                var width = image.width.toDouble()
                var height = image.height.toDouble()

                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width
                        width = maxSize.toDouble()
                    }
                } else {
                    if (height > maxSize) {
                        width *= maxSize / height
                        height = maxSize.toDouble()
                    }
                }
                canvas.width = width.toInt()
                canvas.height = height.toInt()
                context?.drawImage(image, 0.0, 0.0, width, height)
                val dataUrl = canvas.toDataURL("image/jpeg")
                val clearBase64 = dataUrl.replace(Regex("^data:.+;base64,"), "")
                //imageBase64 = dataUrl
                //newCow.image = clearBase64
                // sunucuya base64 formatında gönderiyorum
                scope.launch {
                    RestApi.uploadBase64Image(clearBase64)?.let { res ->
                        //console.log("imageupload:", res)
                        Json.decodeFromString<ImageUploadResponse>(res).let { eres ->
                            if (eres.data != null) {
                                val flink = eres.data
                                //console.log("filename:", flink)
                                doc.name = flink
                                val nlist = docLinks.toMutableList()
                                nlist.add(doc)
                                docLinks = nlist.toList()
                            }
                        }
                    }
                }
                //console.log("image-selected")
            }
            if (readerEvent.target != null) {
                image.src = readerEvent.target.asDynamic().result.toString()
            }
            //image.src = readerEvent.target.asDynamic().result as String
        }
        reader.readAsDataURL(file)

    }

    fun uploadPdf(file: File, doc: DocEtut){
        scope.launch {
            file.toBase64()?.let { pdf64 ->
                //console.log("pdfbase64:", pdf64)
                val clearBase64 = pdf64.replace(Regex("^data:.+;base64,"), "")
                RestApi.uploadTempPdf(clearBase64)?.let { res ->
                    console.log("pdfupload:", res)
                    Json.decodeFromString<ImageUploadResponse>(res).let { eres ->
                        if (eres.data != null) {
                            val flink = eres.data
                            //console.log("filename:", flink)
                            var nlist = docLinks.toMutableList()
                            doc.name = flink
                            nlist.add(doc)
                            docLinks = nlist.toList()
                        }
                    }
                }
            }

        }
    }



    fun acceptFileDrop(dropEvt: SyntheticDragEvent) {
        var docsay = docPageCount
        dropEvt.dataTransfer?.items?.let { items ->
            for (i in 0 until items.length) {
                if (items[i]?.kind == "file") {
                    val file = items[i]?.getAsFile()
                    if (docsay < pageLimit) {
                        if (file?.type?.matches(Regex("^(application/pdf)$")) == true) {
                            val reader = FileReader()
                            reader.readAsBinaryString(file)
                            reader.onloadend = {
                                val result = reader.result as String
                                val count = result.split(Regex("/Type[\\s]*/Page[^s]")).size - 1
                                if (docsay + count <= pageLimit) {
                                    docsay += count
                                    numberOfDocs += 1
                                    val sira = numberOfDocs
                                    uploadPdf(file, DocEtut("", count, sira))
                                }else{
                                    scope.launch {
                                        alertModel = AlertModel("Maksimum 10 sayfa ekleyiniz", AlertIcon.Warning, AlertStyle.Danger)
                                        delay(3000)
                                        alertModel = null
                                    }
                                }
                            }

                        } else if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                            docsay += 1
                            numberOfDocs += 1
                            val sira = numberOfDocs
                            //if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                            var ndoc = DocEtut("", 1, sira)
                            scope.launch {
                                resizeAndUploadImage(file, ndoc)
                            }
                        } else {
                            scope.launch {
                                alertModel = AlertModel("Pdf ve resim dosyalarını ekleyebilirsiniz", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }
                    } else {
                        scope.launch {
                            alertModel = AlertModel("Maksimum 10 sayfa ekleyiniz", AlertIcon.Warning, AlertStyle.Danger)
                            delay(3000)
                            alertModel = null
                        }
                    }
                }
            }
        }
    }

    fun selectImage() {
        (document.getElementById("file-select") as HTMLInputElement).click()
    }

    fun removeDoc(doc: DocEtut) {
        val nlist = docLinks.toMutableList()
        try {
            nlist.remove(doc)
            docLinks = nlist.toList()
        }catch (e: Exception){}

    }

    fun updateKaynakList(){
        kaynakList = kaynakVm.dersKaynak(ders)
    }


    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(520.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
            .overflow(Overflow.Hidden)
    ) {

        DialogTitle(type.text, onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("etutadd-form")
                .toAttrs()
        ) {

            Row(modifier = Modifier.margin(left = 2.px)
                .margin(bottom = 4.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                if (type == EtutType.KonuTest || type == EtutType.Konu) {
                    MyBSSelect(
                        modifier = Modifier
                            .attrsModifier {
                                attr("value", ders)
                            }
                            .fillMaxWidth(),
                        id = "ders-select",
                        items = dersler,
                        disabled = disableDers,
                        placeholder = "Ders Seçiniz",
                        onItemSelect = { idx, text ->
                            if (idx == -1){
                                ders = ""
                            }else{
                                try {
                                    dersler.find { it == text }?.let {
                                        ders = it
                                        updateKaynakList()
                                    }
                                } catch (_: Exception) {
                                    ders = ""
                                }
                            }
                        }
                    )
                }else if (type == EtutType.Kitap) {
                    SpanText(
                        "Okuma yaptığınız aynı gün kitap ismini ve sayfa sayısını kaydediniz.",
                        modifier = Modifier
                            .fontSize(12.px)

                            .margin(bottom = (-2).px, leftRight = 4.px)
                            .color(AppColors.DarkRed.opacity(0.7f))
                            .fillMaxWidth()
                    )
                }
                BSTimeInput(
                    modifier = Modifier
                        .padding(left = 2.px, right = 1.px)
                        .fontSize(13.px),
                    placeholder = "Ders Saati:",
                    value = saati,
                    onValueChange = {
                        saati = it
                    }
                )
            }

            if (type != EtutType.Kuran) {
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 14.px),
                    type = InputType.Text,
                    value = konu,
                    placeholder = if (type.ordinal < 2) "Konu" else if (type == EtutType.Kitap) "Kitap Adı" else "Sınav Adı",
                    required = true,
                    onValueChange = {
                        konu = it
                    }
                )
                if (ders.isNotEmpty()) {
                    Row(
                        modifier = Modifier
                            .margin(bottom = 4.px),
                        verticalAlignment = Alignment.Bottom,

                    ) {
                        if (etut == null && kaynak == null) {
                            DropDownMenu(
                                menuModifier = Modifier,
                                butonModifier = DropDownStyle.toModifier()
                                    .width(38.px)
                                    .height(38.px)
                                    .padding(6.px)
                                    //.margin(topBottom = 8.px)
                                    .whiteSpace(WhiteSpace.NoWrap)
                                    .onClick {
                                        it.stopPropagation()
                                        val menu = document.getElementById("add-drop") as HTMLDivElement
                                        if (menu.style.display == "block") {
                                            menu.style.display = "none"
                                        } else {
                                            closeDropDowns()
                                            menu.style.display = "block"
                                        }
                                    },
                                id = "add-drop",
                                content = {
                                    FaCaretDown(
                                        modifier = Modifier
                                            .color(AppColors.GrayDark),
                                        size = IconSize.LG
                                    )

                                }) {
                                kaynakList.forEach { kynk ->
                                    Button(
                                        attrs = DropMenuItemStyle.toModifier()
                                            .minWidth(150.px)
                                            .padding(3.px)
                                            .color(AppColors.TextColor)
                                            .fontSize(14.px)
                                            .onClick {
                                                it.stopPropagation()
                                                it.preventDefault()
                                                dersKaynak = kynk.isim
                                                closeDropDowns()
                                            }
                                            .toAttrs()) {
                                        Text(kynk.isim)
                                    }
                                }
                                VDivider()
                                Button(
                                    attrs = DropMenuItemStyle.toModifier()
                                        .minWidth(150.px)
                                        .padding(3.px)
                                        .color(AppColors.DarkRed.opacity(0.7f))
                                        .fontSize(14.px)
                                        .margin(bottom = 2.px)
                                        .onClick {
                                            it.stopPropagation()
                                            it.preventDefault()
                                            showAddKaynak = true
                                            closeDropDowns()

                                        }
                                        .toAttrs()) {
                                    Text("+ Yeni Kaynak Ekle")

                                }
                            }
                        }
                        BSInput(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(leftRight = 6.px),

                            type = InputType.Text,
                            disabled = disableKaynak,
                            value = dersKaynak,
                            placeholder = "Kaynak kitap giriniz veya seçiniz",
                            onValueChange = {
                                dersKaynak = it
                            }
                        )


                        /*
                        MyBSSelect(
                            modifier = Modifier
                                .attrsModifier {
                                    attr("value", kaynak)
                                }
                                .fillMaxWidth(),
                            id = "kaynak-select",
                            items = kaynakList.fold(listOf<String>()) { acc, item ->
                                acc + item.isim
                            },
                            placeholder = "Kaynak Seçiniz",
                            disabled = ders.isEmpty(),
                            onItemSelect = { idx, text ->
                                if (idx == -1) {
                                    kaynak = ""
                                } else {
                                    try {
                                        kaynakList.find { it.isim == text }?.let {
                                            kaynak = it.isim
                                        }
                                    } catch (_: Exception) {
                                        kaynak = ""
                                    }
                                }

                                //console.log(regSnf)
                            }
                        )*/

                        BookPageRangeInput(
                            modifier = Modifier
                                .padding(left = 2.px, right = 1.px)
                                .fontSize(13.px),
                            label = "Sayfalar:",
                            placeholder = "(örnek) 1-15",
                            value = sayfalar,
                            onValueChange = {
                                sayfalar = it
                            }
                        )
                    }
                }
                /*
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(bottom = 14.px),
                    type = InputType.Text,
                    value = notlar,
                    placeholder = "Kaynak, sayfa bilgisi ekleyebilirsiniz.",
                    onValueChange = {
                        notlar = it
                    }
                )*/
            }
        }

        if (type == EtutType.KonuTest || type == EtutType.Deneme) {
            DersGunuSaati("${type.text} Soru sayısı", soru) {
                soru = it
            }
        } else if (type == EtutType.Konu) {
            DersGunuSaati("${type.text} Puanı", puan.toInt()) {
                puan = it.toDouble()
            }
        }

        if (type == EtutType.Konu || type == EtutType.KonuTest) {
            var placeHolder = "Video linki veya çalışma notu ekleyebilirsiniz"
            if (type == EtutType.KonuTest) {
                placeHolder = "Konu testi için link veya belge ekleyebilirsiniz."
            }

            if (etut == null) {
                Row(
                    modifier = Modifier.fillMaxWidth()
                        .padding(topBottom = 6.px)
                        .margin(bottom = 4.px)
                        .onDrop { dropevt ->
                            dropevt.preventDefault()
                            if (doctype != DocType.Link){
                                doctype = DocType.Belge
                                if (docLinks.size < pageLimit) {
                                    acceptFileDrop(dropevt)
                                }
                            }

                        }
                        .onDragOver {
                            it.preventDefault()
                            //fileExist = true
                        },
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    DocTypeSelect(DocType.Link, selected = doctype == DocType.Link, onSelect = {
                        if (doctype == DocType.Link) {
                            doctype = null
                        } else {
                            doctype = DocType.Link
                        }
                    })
                    SpanText(
                        placeHolder,
                        modifier = Modifier
                            .padding(leftRight = 6.px)
                            .fontSize(13.px)
                            .color(AppColors.DarkRed.opacity(0.7f))
                    )
                    DocTypeSelect(DocType.Belge, selected = doctype == DocType.Belge, onSelect = {
                        doctype = DocType.Belge
                        if (docLinks.size < pageLimit) {
                            selectImage()
                        }
                    })
                    /*
                    DocType.entries.forEach {

                    }*/
                }
                Input(
                    type = InputType.File,
                    attrs = Modifier
                        .id("file-select")
                        .display(DisplayStyle.None)
                        .margin(bottom = 10.px)
                        .toAttrs {
                            attr("name", "files")
                            attr("multiple", "true")
                            attr("placeholder", "Select File")
                            attr("accept", "image/*|application/pdf")
                            //attr("accept", "image/*")
                            onInput { evt ->
                                var docsay = docPageCount
                                evt.target.files?.let { files ->
                                    for (i in 0..<files.length) {
                                        if (docsay < pageLimit) {
                                            val file = files[i]
                                            if (file?.type?.matches(Regex("^(application/pdf)$")) == true) {
                                                val reader = FileReader()
                                                reader.readAsBinaryString(file)
                                                reader.onloadend = {
                                                    val result = reader.result as String
                                                    val count = result.split(Regex("/Type\\s*/Page[^s]")).size - 1
                                                    if (docsay + count <= pageLimit) {
                                                        docsay += count
                                                        numberOfDocs += 1
                                                        val sira = numberOfDocs
                                                        uploadPdf(file, DocEtut("", count, sira))
                                                    }else{
                                                        scope.launch {
                                                            alertModel = AlertModel("Maksimum 10 sayfa ekleyiniz", AlertIcon.Warning, AlertStyle.Danger)
                                                            delay(3000)
                                                            alertModel = null
                                                        }
                                                    }
                                                }

                                            } else if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                                                docsay += 1
                                                numberOfDocs += 1
                                                val sira = numberOfDocs
                                                val ndoc = DocEtut("", 1, sira)
                                                //if (file?.type?.matches(Regex("^(image/.*)$")) == true) {
                                                scope.launch {
                                                    resizeAndUploadImage(file, ndoc)
                                                }
                                            }
                                        } else {
                                            scope.launch {
                                                alertModel = AlertModel("Maksimum 10 sayfa ekleyiniz", AlertIcon.Warning, AlertStyle.Danger)
                                                delay(3000)
                                                alertModel = null
                                            }
                                        }
                                    }
                                }
                            }
                        }

                )


            }

            if (doctype == DocType.Link) {
                BSInput(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(leftRight = 3.px)
                        .margin(bottom = 14.px),
                    type = InputType.Text,
                    value = link,
                    placeholder = placeHolder,
                    onValueChange = {
                        link = it
                    }
                )
            } else if (doctype == DocType.Belge && docLinks.isNotEmpty()) {
                Row(modifier = Modifier.fillMaxWidth()
                    //.scrollBehavior(ScrollBehavior.Smooth)
                    .overflow(Overflow.Auto)
                    .whiteSpace(WhiteSpace.NoWrap)
                    .onDrop { dropevt ->
                        dropevt.preventDefault()
                        acceptFileDrop(dropevt)
                    }
                    .onDragOver {
                        it.preventDefault()
                        //fileExist = true
                    }
                    .height(180.px),
                    horizontalArrangement = Arrangement.Start
                ) {
                    docLinks.forEachIndexed { index, doc ->
                        //console.log("image:", Images.TempImgDir, doc)
                        val imglink = "${Images.TempImgDir}${doc.name}"
                        //console.log("imglink: ", imglink)
                        Box(
                            modifier = Modifier.fillMaxSize(),
                            contentAlignment = Alignment.BottomCenter
                        ) {
                            Image(
                                modifier = Modifier
                                    .fillMaxSize()
                                    .maxWidth(170.px)
                                    .borderRadius(r = 10.px)
                                    .objectFit(ObjectFit.Contain),
                                src = if (doc.name.endsWith(".pdf")) Images.PdfIcon else imglink
                            )

                            BSButton(modifier = Modifier.margin(bottom = 8.px),
                                text = "${index + 1} - Sil",
                                size = ButtonSize.Small,
                                variant = ButtonVariant.DangerOutline,
                                onClick = {
                                    removeDoc(doc)
                                }
                            )
                        }

                    }
                }
            }


        } else if (type == EtutType.Kitap || type == EtutType.Kuran) {
            DersGunuSaati("Okunan sayfa sayısı", soru) {
                soru = it
            }
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.End
        ) {
            BSButton(
                text = "Kapat",
                variant = ButtonVariant.SecondaryOutline,
                onClick = {
                    onClose()
                }
            )
            BSButton(
                modifier = Modifier.margin(left = 10.px),
                text = "Kaydet",
                variant = ButtonVariant.Success,
                onClick = {
                    saveEtut()
                }
            )
        }
        if (etut == null && AppData.appUser.role > 0
            && AppData.ViewModel.ogrenciVM.ogrenciListem.isNotEmpty()
        ) {
            fun getSnf(ogr: OgrenciInfo): String {
                if (ogr.snf == 13) return " (Mezun)"
                else if (ogr.snf == 20) return " (Ünv.)"
                else return " (${ogr.snf}.Snf)"
            }
            VDivider(modifier = Modifier.margin(topBottom = 1.px))
            Span(
                attrs = Modifier.color(AppColors.DarkRed)
                    .margin(bottom = 2.px)
                    .fontSize(11.px).toAttrs()
            ) {
                Text("Diğer öğrencilerinize de aynı görevi ekleyebilirsiniz.")
            }

            SimpleGrid(numColumns(base = 4), modifier = Modifier.fillMaxWidth()) {
                AppData.ViewModel.ogrenciVM.ogrenciListem
                    .filter { it.uuid != AppData.ViewModel.ogrenciVM.selectedOgrenci?.uuid }
                    .sortedBy { it.snf }
                    .forEach { ogr ->
                        Box(modifier = Modifier.margin(right = 2.px)) {
                            Row(
                                modifier = Modifier.fillMaxWidth()
                                    .backgroundColor(AppColors.Gray.opacity(0.4f))
                                    .borderRadius(r = 4.px)
                                    .margin(right = 2.px),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                BSCheckbox(
                                    modifier = Modifier
                                        .margin(0.px)
                                        .color(AppColors.TextColor),
                                    defaultChecked = false,
                                    label = "",
                                    onClick = {
                                        if (it) {
                                            uuidList.add(ogr.uuid)
                                        } else {
                                            uuidList.remove(ogr.uuid)
                                        }
                                    }
                                )
                                Span(attrs = Modifier.fontSize(13.px).toAttrs()) {
                                    Text(ogr.name)
                                    Span(attrs = Modifier.fontSize(10.px).toAttrs()) { Text(getSnf(ogr)) }
                                }
                            }
                        }
                    }

            }
        }

    }

    if (alertModel != null) {
        BSAlert(
            modifier = Modifier.zIndex(99),
            message = alertModel!!.message,
            dismissible = false,
            icon = alertModel!!.icon,
            style = alertModel!!.style
        )
    }

    if (showAddKaynak){
        DialogView {
            AddNewKaynak(ders =ders, onClose = {
                showAddKaynak = false
            }, onAdded = {
                showAddKaynak = false
            })
        }

    }

}


enum class DocType(val text: String, val icon: String) {
    Link("Link Ekle", "link"),
    Belge("Belge Ekle", "file-contract"),
}


@Composable
fun DocTypeSelect(docType: DocType, selected: Boolean, onSelect: () -> Unit) {
    Row(
        modifier = RoleButtonStyle.toModifier()
            .margin(leftRight = 6.px, bottom = 4.px)
            .minWidth(100.px)
            .padding(4.px)
            .backgroundColor(if (selected) AppColors.TextColor.opacity(0.3f) else AppColors.TextColor.opacity(0.2f))
            .onClick {
                onSelect()
            },
        verticalAlignment = Alignment.CenterVertically
    ) {
        FaIcon(docType.icon, Modifier.color(AppColors.Gray), IconCategory.SOLID, size = IconSize.SM)
        SpanText(
            docType.text,
            modifier = Modifier.fontSize(16.px)
                .color(AppColors.TextColor.opacity(0.6f))
        )

    }
}

package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.kelimesoft.etutpro.models.AppData
import com.kelimesoft.etutpro.models.Routes
import com.kelimesoft.etutpro.models.Screens
import com.varabyte.kobweb.core.rememberPageContext


@Composable
fun RouterPage() {
    when(AppData.appRoutes.activeRoute) {
        Screens.Login -> Giris()
        Screens.Home -> OzetPage()
        Screens.Ozet -> OzetPage()
        Screens.Ders -> DersPage()
        Screens.Etut -> EtutPage()
        Screens.Profil -> ProfilPage()
    }
}
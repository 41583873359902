package com.kelimesoft.etutpro.models

import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle


data class AlertModel(
    val message: String,
    val icon: AlertIcon,
    val style: AlertStyle
)

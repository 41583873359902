package com.kelimesoft.etutpro.models

import kotlinx.serialization.Serializable

@Serializable
data class DersItem(
    val id: Long,
    var dersad: String,
    var renk: Int,
    var gun: Int,
    var sira: Int,
)

@Serializable
data class NewDersBinding(
    var uuid: String = AppData.appUser.uuid,
    val ders: DersItem
)

@Serializable
data class DersOrderBinding(
    var uuid: String = AppData.appUser.uuid,
    val ders: DersItem,
    val gun: Int,
    val sira: Int
)





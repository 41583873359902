package com.kelimesoft.etutpro.utils


import kotlinx.coroutines.CompletableDeferred
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.js.Date
import kotlin.math.floor
import kotlin.math.roundToInt


fun Double.round(r: Int = 1): String {
    val dbl = this as Any
    val fl = r as Any
    return js("dbl.toFixed(fl)") as String
    //return js("${this}.toFixed($r)") as String
    /*
    var pow = 10.0
    if(r == 2) pow = 100.0
    if(r == 3) pow = 1000.0
    if(r == 0) pow = 1.0
    return (this * pow).roundToInt() / 100.0

     */
}

fun Double.rountTo(r: Int = 1): Double {
    var pow = 10.0
    when (r) {
        2 -> pow = 100.0
        3 -> pow = 1000.0
        0 -> pow = 1.0
    }
    return (this * pow).roundToInt() / pow
}


fun asCurrency(amount: Double, locale: String = "en-US", currency: String = "USD"): String {
    val formatter = js("new Intl.NumberFormat(locale, { style: 'currency', currency: currency })")
    return formatter.format(amount) as String
}


fun Date.startOfDay(): Date {
    //console.log("startofday: $this")
    return Date(
        year = this.getFullYear(),
        month = this.getMonth(),
        day = this.getDate(),
        hour = 0,
        minute = 0,
        second = 1
    )
}

fun Date.endOfDay(): Date {
    //console.log("startofday: $this")
    return Date(
        year = this.getFullYear(),
        month = this.getMonth(),
        day = this.getDate(),
        hour = 23,
        minute = 59,
        second = 59
    )
}

fun Date.lastDayOfMonth(): Date {
    return Date(year = this.getFullYear(), month = this.getMonth() + 1, day = 0, hour = 23, minute = 59, second = 59)
}

fun Date.firstDayOfMonth(): Date {
    return Date(year = this.getFullYear(), month = this.getMonth(), day = 1, hour = 0, minute = 0, second = 1)
}

fun Date.firstDayOfQuarter(): Date {
    return Date(this.getFullYear(), (floor(this.getMonth().toDouble() / 3.0) * 3).toInt(), 1).startOfDay()
}

fun Date.lastDayOfQuarter(): Date {
    val fm = this.firstDayOfQuarter()
    return Date(fm.getFullYear(), fm.getMonth() + 2).lastDayOfMonth()
}

fun Date.firstDayOfYear(): Date {
    return Date(year = this.getFullYear(), month = 0, day = 1, hour = 0, minute = 0, second = 1)
}

fun Date.lastDayOfYear(): Date {
    return Date(year = this.getFullYear(), month = 11, day = 31, hour = 23, minute = 59, second = 59)
}

fun Date.daysInMonth(): Int {
    return this.lastDayOfMonth().getDate()
}


fun Date.previousMonth(): Date {
    //console.log("startofday: $this")
    var year = this.getFullYear()
    var month = this.getMonth()
    if (month == 0) {
        year -= 1
        month = 11
    } else {
        month -= 1
    }
    return Date(year = year, month = month, day = this.getDate(), hour = this.getHours())
}

fun Date.nextMonth(): Date {
    //console.log("startofday: $this")
    var year = this.getFullYear()
    var month = this.getMonth()
    if (month == 11) {
        year += 1
        month = 0
    } else {
        month += 1
    }
    return Date(year = year, month = month, day = this.getDate(), hour = this.getHours())
}


fun Date.isBefore(secondDate: Date): Boolean {
    //val thisLocal = Date(this.toLocaleDateString())
    if (this.getFullYear() < secondDate.getFullYear()) return true
    if (this.getFullYear() == secondDate.getFullYear()) {
        if (this.getMonth() < secondDate.getMonth()) return true
        if (this.getMonth() == secondDate.getMonth()) {
            if (this.getDate() < secondDate.getDate()) return true
            if (this.getDate() == secondDate.getDate()) {
                if (this.getHours() < secondDate.getHours()) return true
                if (this.getHours() == secondDate.getHours()) {
                    if (this.getMinutes() < secondDate.getMinutes()) return true
                }
            }
        }
    }
    return false
}

fun Date.isAfter(secondDate: Date): Boolean {
    return secondDate.isBefore(this)
}

fun Date.sameDay(secondDate: Date): Boolean {
    if (this.getFullYear() == secondDate.getFullYear()) {
        if (this.getMonth() == secondDate.getMonth()) {
            if (this.getDate() == secondDate.getDate()) {
                return true
            }
        }
    }
    return false
}


fun Date.dateToDbStr(): String {
    val year = this.getFullYear()
    // Adding 1 to month as it is 0-indexed
    val month = (this.getMonth() + 1).toString().padStart(2, '0')
    val day = this.getDate().toString().padStart(2, '0')
    return "$year-$month-$day"
}

fun Date.dateToDbLongStr(): String {
    val year = this.getFullYear()
    val month = (this.getMonth() + 1).toString().padStart(2, '0')
    val day = this.getDate().toString().padStart(2, '0')
    val hour = this.getHours().toString().padStart(2, '0')
    val minutes = this.getMinutes().toString().padStart(2, '0')
    return "$year-$month-$day $hour:$minutes"
}


fun dateDiff(laterDate: Date, prevDate: Date, diff: String? = "m"): Int {
    val dif = laterDate.getTime() - prevDate.getTime()
    val inHours = (dif / 1000 / 60 / 60)
    var fark = 0.0
    when (diff) {
        "y" -> fark = inHours / 24 / 365.3
        "m" -> fark = inHours / 24 / 30.41
        "w" -> fark = inHours / 24 / 7
        "d" -> fark = inHours / 24
        else -> fark = inHours //hours olarak
    }
    return fark.toInt()
}

fun Date.addDays(days: Int): Date {
    return Date(this.getTime() + (days * 24 * 60 * 60 * 1000))
}


fun String.yasiniBul(): String {
    val now = Date()
    var previousDate = Date(this)
    var yasi = "";
    val yilsay = dateDiff(now, previousDate, "y")
    if (yilsay > 0) {
        yasi = "$yilsay Yıl,";
        previousDate = Date(
            previousDate.getFullYear() + yilsay, previousDate.getMonth(), previousDate.getDate()
        )
    }
    val aysay = dateDiff(now, previousDate, "m")
    if (aysay > 0) {
        if (yilsay > 0) {
            yasi += "$aysay Ay";
            return yasi.replace(",", ", ")
        }
        yasi += "$aysay Ay,";
        previousDate = Date(
            previousDate.getFullYear(), previousDate.getMonth() + aysay, previousDate.getDate()
        );
    }
    val gunsay = dateDiff(now, previousDate, "d")
    if (gunsay > 0) {
        yasi += "$gunsay Gün";
    }
    val lastchar = yasi[yasi.length - 1];
    if (lastchar == ',') {
        yasi = yasi.substring(0, yasi.length - 1);
    }
    return yasi.replace(",", ", ");
}

fun String.isVideo(): Boolean {
    return (this.contains("youtube")
            || this.contains("watch")
            || this.contains("vimeo")
            || this.endsWith(".mp4")
            || this.contains("dailymotion"))
}

suspend fun File.toBase64(): String? {
    val reader = FileReader()
    val completableDeferred = CompletableDeferred<String?>()
    reader.onload = { _ ->
        val result = reader.result
        if (result is String) {
            completableDeferred.complete(result)
        } else {
            completableDeferred.complete(null)
        }
    }
    reader.readAsDataURL(this)
    return completableDeferred.await()
}


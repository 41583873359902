package com.kelimesoft.etutpro.pages.ozet

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.AppData
import com.kelimesoft.etutpro.models.opacity
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun AddOgrenciInfo() {
    Row (modifier = Modifier
        .fillMaxWidth()
        .fontSize(10.px)
        .color(AppColors.ViewWhite.opacity(0.8f)),
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        //console.log("addogrenciinfo: ", AppData.appUser.toString())
        if (AppData.appUser.velicode.isNotEmpty()){
            Row {
                Span(attrs = Modifier
                    .margin(0.px)
                    .toAttrs()){
                    Text("Veli Aktivasyon Kodu:")
                    Span(attrs = Modifier
                        .margin(leftRight = 6.px)
                        .fontWeight(FontWeight.SemiBold)
                        .toAttrs()) {
                        Text(AppData.appUser.velicode)
                    }

                }
            }
        }
        if (AppData.appUser.koccode.isNotEmpty()) {
            Row {
                Span(
                    attrs = Modifier
                        .margin(0.px)
                        .toAttrs()
                ) {
                    Text("Eğitim Koçu Aktivasyon Kodu:")
                    Span(
                        attrs = Modifier
                            .margin(leftRight = 6.px)
                            .fontWeight(FontWeight.SemiBold)
                            .toAttrs()
                    ) {
                        Text(AppData.appUser.koccode)
                    }
                }
            }
        }
    }
}
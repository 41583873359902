package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.opacity
import com.kelimesoft.etutpro.styles.SmallAddButonStyle
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun SmallAddButton(modifier: Modifier = Modifier, onClick: () -> Unit) {
    Box(
        modifier = SmallAddButonStyle.toModifier()

            .margin(right = 4.px)
            .then(modifier)
            .onClick {
                onClick()
            }, contentAlignment = Alignment.Center
    ) {
        SpanText(
            " + ",
            modifier = Modifier
                .fontSize(16.px)
                .fontWeight(FontWeight.SemiBold)
                .color(Colors.White)
                .textAlign(TextAlign.Center)
                .color(Colors.White)
        )
    }
}
package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.pages.ozet.AddOgrenciInfo
import com.kelimesoft.etutpro.styles.AppBarButtonStyle
import com.kelimesoft.etutpro.styles.NavTextStyle
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaAward
import com.varabyte.kobweb.silk.components.icons.fa.FaBookOpen
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text


@Composable
fun MyNavBar(page: Screens, onSelect: () -> Unit,
             addOgrenci: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    val context = rememberPageContext()

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(AppColors.AppBarColor)
            .borderRadius(r = 20.px)

    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(6.px)
                .backgroundImage(AppColors.GradientPrimary)
                .borderRadius(r = 20.px)

        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {

                AppBrand()
                if (breakpoint > Breakpoint.SM) {
                    MenuButtons(context, 16)
                }
                Spacer()
                if (page == Screens.Etut) {
                    KitapReadingInfo()
                    AwardInfo()
                }

                UserInfo(breakpoint)
            }
            if (breakpoint <= Breakpoint.SM) {
                MenuButtons(context, 14)
            }

            if (AppData.appUser.role == 0 && page == Screens.Ozet){
                if (AppData.appUser.koccode.isNotEmpty() || AppData.appUser.velicode.isNotEmpty()){
                    AddOgrenciInfo()
                }
            }else if (AppData.appUser.role > 0) {
                VDivider(modifier = Modifier.margin(bottom = 2.px))
                OgrenciListView(breakpoint, onSelect = {
                    onSelect()
                }, addOgrenci = {
                    addOgrenci()
                })
            }

        }
    }


}


@Composable
fun AppBrand() {
    val context = rememberPageContext()
    Row(
        modifier = Modifier.margin(right = 20.px)
            .cursor(Cursor.Pointer).onClick {
                AppData.appRoutes.navigateTo(context, Screens.Ozet)
            }
        ,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            modifier = Modifier
                .margin(bottom = 2.px)
                .size(22.px),
            src = Images.AppIcon,
            alt = "etut pro ile okulda başarı"

        )
        SpanText(
            "EtütPro", modifier = Modifier.color(AppColors.ViewWhite)
                .margin(left = 4.px)
                .fontSize(22.px)
        )

    }

}

@Composable
fun MenuButtons(context: PageContext, size: Int) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        AppBarMenuButton(
            "Özet", Images.Ozet,
            AppData.appRoutes.activeRoute == Screens.Ozet, size
        ) {

            AppData.appRoutes.navigateTo(context, Screens.Ozet)
        }
        AppBarMenuButton(
            "Ders Programı", Images.Ders,
            AppData.appRoutes.activeRoute == Screens.Ders, size
        ) {
            AppData.appRoutes.navigateTo(context, Screens.Ders)
        }
        AppBarMenuButton(
            "Etüt Planı", Images.Etut,
            AppData.appRoutes.activeRoute == Screens.Etut, size
        ) {
            AppData.appRoutes.navigateTo(context, Screens.Etut)
        }
    }
}

@Composable
fun AppBarMenuButton(
    title: String, image: String,
    selected: Boolean = false,
    size: Int,
    onSelect: () -> Unit
) {
    Row(modifier = Modifier
        .onClick {
            console.log("onclick: ", title)
            onSelect()
        }
        .cursor(Cursor.Pointer)
        .margin(left = 20.px),
        verticalAlignment = Alignment.CenterVertically) {
        Image(
            modifier = Modifier
                .size(14.px),
            src = image,
            alt = "etut pro ile okulda başarı"
        )
        SpanText(
            title, modifier = NavTextStyle.toModifier()
                .color(AppColors.ViewWhite.opacity(if (selected) 1.0f else 0.5f))
                .margin(left = 4.px)
                .fontSize(size.px)
        )
    }
}


@Composable
fun OgrenciListView(breakpoint: Breakpoint,
                    onSelect: (OgrenciInfo) -> Unit,
                    addOgrenci: () -> Unit
                    ) {
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    Column(
        modifier = Modifier.fillMaxWidth()
            .color(AppColors.ViewWhite.opacity(0.7f))
            .padding(leftRight = 4.px)
    ) {
        if (breakpoint < Breakpoint.SM) {
            SpanText("Öğrencilerim: ")
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (breakpoint >= Breakpoint.SM) {
                SpanText("Öğrencilerim: ")
            }
            SimpleGrid(numColumns = numColumns(base = 2, sm = 3, md = 5, lg = 6), modifier = Modifier.fillMaxWidth()) {
                ogrenciVM.ogrenciListem.forEach { ogrenci ->
                    OgrenciRow(ogrenci, ogrenci == ogrenciVM.selectedOgrenci) {
                        if (ogrenci != ogrenciVM.selectedOgrenci) {
                            ogrenciVM.selectOgrenci(ogrenci)
                            AppData.ViewModel.kaynakVM.allKaynakList = listOf()
                            onSelect(ogrenci)
                        }
                    }
                }
            }
            BSButton(
                modifier = Modifier.maxHeight(26.px)
                    .textOverflow(TextOverflow.Ellipsis)
                    .borderRadius(r = 10.px)
                    .margin(left = 4.px),
                text = "+Ekle", size = ButtonSize.Small,
                variant = ButtonVariant.LightOutline
            ) {
                addOgrenci()
            }

        }
    }

}


@Composable
fun UserInfo(breakpoint: Breakpoint) {
    val context = rememberPageContext()
    Row(
        modifier = Modifier
            .borderRadius(r = 16.px)
            .backgroundColor(AppColors.AppBarButonColor)
            .margin(2.px)
            .minHeight(48.px)
            .padding(4.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            modifier = Modifier
                .margin(bottom = 2.px)
                .size(22.px),
            src = Images.Ogrenci,
            alt = "etüt yapan başarılı öğrenci profili"

        )
        if (breakpoint > Breakpoint.SM) {
            Column(modifier = Modifier.margin(left = 6.px)) {
                Span(
                    attrs = Modifier.color(AppColors.ViewWhite)
                        .fontSize(16.px).toAttrs()
                ) {
                    Text(AppData.appUser.name)
                }

                Span(
                    attrs = Modifier
                        .color(AppColors.ViewWhite.opacity(0.5f))
                        .fontSize(12.px).toAttrs()
                ) {
                    Text(AppData.appUser.name)
                }

                //SpanText(AppData.appUser.e)
            }
        }



        Button(
            attrs = AppBarButtonStyle.toModifier()
                .margin(left = 8.px)
                .onClick {
                    AppData.logOut(context)
                }
                .toAttrs()
        ) {
            FaSignOut(size = IconSize.XL, modifier = Modifier.color(AppColors.DarkRed))
        }

    }
}


@Composable
fun AwardInfo() {
    Row(verticalAlignment = Alignment.CenterVertically) {
        Column(
            modifier = Modifier.borderRadius(r = 50.percent)
                .size(50.px)
                .minSize(48.px)
                .margin(right = 10.px)
                .backgroundColor(AppColors.AppBarButonColor),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            FaAward(
                size = IconSize.XL,
                modifier = Modifier.color(AppColors.AwardColor)
            )
            Span(
                attrs = Modifier
                    .margin(top = 10.px)
                    .color(AppColors.BallonYellow.opacity(0.8f))
                    .fontWeight(FontWeight.Medium)
                    .fontSize(15.px).toAttrs()
            ) {
                Text(AppData.ViewModel.etutVM.userAward.toString())
            }
        }
    }


}


@Composable
fun KitapReadingInfo() {
    Row(verticalAlignment = Alignment.CenterVertically) {
        Column(
            modifier = Modifier.borderRadius(r = 50.percent)
                .size(50.px)
                .minSize(48.px)
                .margin(right = 10.px)
                .backgroundColor(AppColors.AppBarButonColor),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            FaBookOpen(
                size = IconSize.XL,
                modifier = Modifier.color(AppColors.Gray)
            )
            Span(
                attrs = Modifier
                    .margin(top = 10.px)
                    .color(AppColors.BallonYellow.opacity(0.8f))
                    .fontWeight(FontWeight.Medium)
                    .fontSize(15.px).toAttrs()
            ) {
                Text(AppData.ViewModel.etutVM.kitapSayfa.toString())
            }
        }
    }


}

@Composable
fun OgrenciRow(ogrenci: OgrenciInfo, selected: Boolean, onSelect: () -> Unit) {
    fun getSnf(): String {
        if (ogrenci.snf == 13) return " (Mezun)"
        else if (ogrenci.snf == 20) return " (Ünv.)"
        else if (ogrenci.snf == 0) return " (O.Ö.)"
        else return " (${ogrenci.snf}.Snf)"
    }
    Row(modifier = Modifier
        .maxWidth(160.px)
        .textOverflow(TextOverflow.Ellipsis)
        .margin(left = 6.px, bottom = 2.px)
        .borderRadius(r = 8.px)
        .onClick {
            onSelect()
        }
        .thenIf(selected) {
            Modifier.border(1.px, color = AppColors.ViewWhite, style = LineStyle.Solid)
                .color(AppColors.ViewWhite)
        }
        .padding(leftRight = 6.px, topBottom = 2.px)
        .cursor(Cursor.Pointer)
        .backgroundColor(AppColors.AppBarButonColor),
        verticalAlignment = Alignment.CenterVertically) {
        Image(
            src = Images.AppIcon,
            modifier = Modifier.size(20.px)
                .margin(left = 2.px)
        )

        Span(attrs = Modifier.fontSize(13.px).toAttrs()) {
            Text(ogrenci.name)
            Span(attrs = Modifier.fontSize(10.px).toAttrs()) { Text(getSnf()) }
        }


    }
}
package com.kelimesoft.etutpro.pages

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.components.PageBox
import com.kelimesoft.etutpro.models.AppColors
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Embed
import org.jetbrains.compose.web.dom.Iframe
import org.w3c.dom.*
import org.w3c.dom.css.get

@OptIn(ExperimentalComposeWebApi::class)
@Page("/flip")
@Composable
fun Flip() {
    PageBox {
        Iframe(
            attrs = Modifier
                .fillMaxSize()
            .toAttrs {
                attr("src", "https://etutpro.com/etutdocs/9362641897-9E8.pdf")
            }) {


        }

    }


    /*
    var activeDoublePage by remember { mutableStateOf(0) }
    var activeSinglePage by remember { mutableStateOf(0) }
    val breakpoint = rememberBreakpoint()


    fun nexPage(){
            if (activeDoublePage < (imageList.size / 2) + 2){
                activeDoublePage += 2
            }
            if (activeSinglePage < imageList.size -1){
                activeSinglePage += 1
            }

    }

    fun prevPage(){
            if (activeDoublePage > 0){
                activeDoublePage -= 2
            }

            if (activeSinglePage > 0){
                activeSinglePage -= 1
            }

    }
    PageBox {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .maxWidth(1920.px)
                .overflow(Overflow.Hidden)
                .backgroundColor(AppColors.ViewWhite),
            contentAlignment = Alignment.TopEnd

        ) {
            var boxzoom by remember { mutableStateOf(1.0) }
            Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center){
                Row(modifier = Modifier.fillMaxSize()
                    .overflow(Overflow.Hidden)
                    .maxWidth(1800.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Button(attrs = Modifier
                        .onClick {
                            prevPage()
                        }
                        .toAttrs()) {
                        FaChevronLeft(size = IconSize.LG)
                    }
                    Box(modifier = Modifier.fillMaxWidth()
                        .fillMaxHeight(96.percent)
                        .overflow(Overflow.Auto)
                        .attrsModifier {
                            attr("perspective","1000px;")
                        },
                        contentAlignment = Alignment.Center) {

                        if (breakpoint > Breakpoint.LG) {
                            for (i in imageList.indices step 2) {
                                Box(modifier = Modifier.fillMaxSize()
                                    .overflow(Overflow.Auto)){
                                Row(
                                    modifier = Modifier.fillMaxSize()
                                        .id("pageRow$i")
                                        .styleModifier {
                                            property("zoom", boxzoom)
                                        }
                                        .zIndex(if (activeDoublePage == i) imageList.size else imageList.size - i),
                                    horizontalArrangement = Arrangement.Center,
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    Box(
                                        modifier = Modifier
                                            .fillMaxSize()
                                            .backgroundColor(Color.white)
                                            .overflow(Overflow.Hidden),
                                        contentAlignment = Alignment.Center
                                    ) {
                                        Image(src = "https://etutpro.com/imgtemp/${imageList[i]}", modifier = Modifier.objectFit(ObjectFit.Contain))
                                        SpanText("Page${i + 1}", modifier = Modifier.fontSize(30.px))
                                    }

                                    Box(modifier = Modifier.width(10.px)) { }

                                    Box(
                                        modifier = Modifier
                                            .fillMaxSize()
                                            .backgroundColor(Color.white)
                                            .overflow(Overflow.Hidden),
                                        contentAlignment = Alignment.Center
                                    ) {
                                        Image(src = "https://etutpro.com/imgtemp/${imageList[i + 1]}", modifier = Modifier.objectFit(ObjectFit.Contain))
                                        SpanText("Page${i + 2}", modifier = Modifier.fontSize(30.px))
                                    }
                                }
                            }

                            }
                        }else {
                            for (i in imageList.indices) {
                                Box(
                                    modifier = Modifier
                                        .id("boxImg$i")
                                        .fillMaxSize()
                                        .styleModifier {
                                            property("zoom", boxzoom)
                                           // property("transition", "transform 5s, filter 3s ease-in-out;")
                                        }
                                        .backgroundColor(Color.white)
                                        .transition(CSSTransition(property = "zoom", duration = 200.ms))
                                        .id("pageBox$i")
                                        .zIndex(if (activeSinglePage == i) imageList.size else imageList.size - i)
                                        .thenIf(boxzoom == 1.0){
                                            Modifier.overflow(Overflow.Hidden)
                                        }.thenIf(boxzoom > 1.0){
                                            Modifier.overflow(Overflow.Auto)
                                        },


                                    contentAlignment = Alignment.Center
                                ) {
                                    Image(src = "https://etutpro.com/imgtemp/${imageList[i]}", modifier = Modifier.objectFit(ObjectFit.Cover))
                                    SpanText("Page${i + 1}", modifier = Modifier.fontSize(30.px))
                                }


                            }
                        }
                    }

                    Button(attrs = Modifier
                        .onClick {
                            nexPage()
                        }
                        .toAttrs()) {
                        FaChevronRight(size = IconSize.LG)
                    }


                }
            }

            Column(modifier = Modifier.padding(6.px)
                .zIndex(100)) {
                Button(attrs = Modifier
                    .margin(6.px)
                    .onClick {
                        if (boxzoom < 8.0){
                            boxzoom += 0.5
                        }else{
                            boxzoom = 8.0
                        }
                    }
                    .toAttrs()) {
                    FaPlus(size = IconSize.LG)
                }

                Button(attrs = Modifier
                    .margin(6.px)
                    .onClick {
                        if (boxzoom > 1.0){
                            boxzoom -= 0.5
                        }else{
                            boxzoom = 1.0
                        }

                    }
                    .toAttrs()) {
                    FaMinus(size = IconSize.LG)
                }
            }

        }
    }

     */

}
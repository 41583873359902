package com.kelimesoft.etutpro.components

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.styles.CloseButtonStyle
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.functions.blur
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

enum class DialogType {
    BottomSheet, CenterDialog
}

@Composable
fun DialogView(type: DialogType = DialogType.CenterDialog, content: @Composable () -> Unit) {
    //var margin by remember { mutableStateOf(800) }
    var height by remember { mutableStateOf(5) }
    LaunchedEffect(true){
       for(h in 5..100 step(5)){
           delay(10)
           height = h
       }
    }

    Box(
        modifier = Modifier.fillMaxSize()
            .backgroundColor(if(type == DialogType.BottomSheet)
                AppColors.GrayDark.copyf(alpha = 0.8f) else AppColors.GrayDark.copyf(alpha = 0.3f))
            .styleModifier {
                blur(5.px)
            }
            .zIndex(9),
        contentAlignment = if (type == DialogType.BottomSheet) Alignment.BottomCenter
        else Alignment.Center

    ) {
        Box(
            modifier = Modifier.fillMaxWidth()
                //child parent ile beraber büyümesi için overflow
                .overflow(Overflow.Hidden)
                .fillMaxHeight((height).percent),
            contentAlignment = if (type == DialogType.BottomSheet) Alignment.BottomCenter
            else Alignment.Center

        ) {
            content()
        }
    }
}


@Composable
fun DialogTitle(title: String, onClose: () -> Unit){
    Row(modifier = Modifier.fillMaxWidth()
        .margin(top = 6.px, bottom = 3.px)
        .padding(leftRight = 7.px),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Span(attrs = Modifier
            .fontSize(16.px)
            .fontWeight(FontWeight.SemiBold)
            .color(AppColors.TextColor)
            .toAttrs()) {
            Text(title)
        }
        Spacer()
        Box(modifier = CloseButtonStyle.toModifier()
            .onClick {
                onClose()
            }
        ) {
            FaIcon("close", modifier = Modifier
                , IconCategory.SOLID,
                size = IconSize.XL
            )
        }
    }
}
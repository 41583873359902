package com.kelimesoft.etutpro.models

import kotlinx.browser.window

object AppStorage {
    private val localStorage = window.localStorage
    const val userUUID = "uuid"
    const val userName = "user"
    const val Role = "role"
    const val selectedOgrenci = "selectOgrenci"
    const val ShowKaynak = "showKaynak"

    fun getBool(key: String, default: Boolean = false): Boolean{
        val str = localStorage.getItem(key) ?: return default
        return  str == "1"
    }

    fun getString(key: String, default: String = ""): String{
        return  localStorage.getItem(key) ?: return default
    }

    fun getInt(key: String, default: Int = 0): Int{
        val str = localStorage.getItem(key) ?: return default
        return  str.toInt()
    }

    fun setBool(key: String, value: Boolean){
        localStorage.setItem(key, if (value) "1" else "0")
    }
    fun setString(key: String, value: String){
        localStorage.setItem(key, value)
    }

    fun setInt(key: String, value: Int){
        localStorage.setItem(key, value.toString())
    }

    fun remove(key: String){
        localStorage.removeItem(key)
    }


}
package com.kelimesoft.etutpro.styles

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.style.CssStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val LeftPaneButtonStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .border(0.px)
            .borderRadius(
                topRight = 6.px,
                bottomRight = 6.px
            )
            .padding(leftRight = 10.px, topBottom = 4.px)
            .margin(bottom = 6.px)
            .fontSize(13.px)
            .backgroundColor(Colors.Black.copyf(alpha = 0.05f))
            .color(Colors.White)
            .cursor(Cursor.Pointer)
            .opacity(90.percent)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
                CSSTransition(property = "opacity", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .opacity(90.percent)
            .backgroundColor(Colors.Black.copyf(alpha = 0.3f))
    }

}

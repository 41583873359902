package com.kelimesoft.etutpro.pages.ozet

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.DersItem
import com.kelimesoft.etutpro.models.Values
import com.kelimesoft.etutpro.models.WeekDays
import com.kelimesoft.etutpro.utils.Funcs
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import kotlin.js.Date

@Composable
fun BugunDersler(dersMapList: Map<WeekDays, List<DersItem>>) {
    var title = "Bugünün Dersleri"
    var weekDay = Funcs.getWeekDay(Date())
    val hour = Date().getHours()
    if (weekDay > 5) {
        weekDay = 1
        title = "Pazartesi Dersleri"
    } else if (hour > 18) {
        if (weekDay == 7) weekDay = 1
        else weekDay += 1
        title = "Yarının Dersleri"
    }

    HedefColumn(
        title = title
    ) {
        dersMapList.filter { it.key.day == weekDay }.forEach { dersMap ->
            dersMap.value.forEach { ders ->
                SpanText(
                    ders.dersad,
                    modifier = Modifier
                        .fillMaxWidth()
                        .fontFamily(Values.FONT_TOHOMA)
                        .fontWeight(FontWeight.Medium)
                        .fontSize(18.px)
                        .margin(bottom = 4.px)
                        .textAlign(TextAlign.Center)
                        .color(AppColors.colorDersList[ders.renk])

                )
            }
        }

    }

}




package com.kelimesoft.etutpro.pages.ozet

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.styles.FaaliyetDersChevStyle
import com.kelimesoft.etutpro.styles.OzetDersColStyle
import com.kelimesoft.etutpro.utils.round
import com.kelimesoft.etutpro.utils.rountTo
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun PeriodOzet(
    title: String,
    dersMap: List<DersMap>, kitap: Int, score: Double,
    kitapList: List<KitapOzet>, denemeList: List<DenemeData>
) {
    val dersList = AppData.ViewModel.dersVM.dersList
    var showKonu = remember { mutableStateOf("") }
    Column(modifier = Modifier
        .fillMaxWidth()
        .minWidth(200.px)
    ) {
        HedefColumn(
            title = title
        ) {
            PeriodScores(kitap, score)

        }

        OzetSubFaaliyet(
            title = "Konu Testleri"
        ){
            dersMap.forEach { map ->
                var color = AppColors.colorDersList[(0..8).random()]
                dersList.find { it.dersad == map.ders }?.let {
                    color = AppColors.colorDersList[it.renk]
                }
                Column(
                    modifier = OzetDersColStyle
                        .toModifier()
                        .backgroundColor(color.opacity(0.3f))
                        .fillMaxWidth()
                ) {
                    val soru = map.konulist?.sumOf { it.soru } ?: 0
                    val dogru = map.konulist?.sumOf { it.puan } ?: 0.0
                    val kalan = map.konulist?.sumOf { it.kalan } ?: 0
                    FaaliyetDersView(map.ders, soru, dogru, kalan, showKonu)
                    if (showKonu.value == map.ders) {
                        map.konulist?.forEach { faliyet ->
                            FaaliyetKonuView(faliyet)
                        }
                    }

                }

            }
        }


        OzetSubFaaliyet(
            title = "Sınav / Deneme"
        ) {
            Column(
                modifier = OzetDersColStyle.toModifier().fillMaxWidth()
            ) {
                denemeList.forEach { deneme ->
                    DenemeView(deneme)
                }
            }

        }

        OzetSubFaaliyet(
            title = "Okuduğum Kitaplar"
        ) {
            Column(
                modifier = OzetDersColStyle.toModifier().fillMaxWidth()
            ) {
                kitapList.forEach { kitap ->
                    FaaliyetKitapView(kitap)
                }
            }

        }
    }
}

/*
@Composable
fun HaftaFaaliyet(dersMap: List<DersMap>, kitap: Int, score: Int) {
    val dersList = AppData.ViewModel.dersVM.dersList
    var showKonu = remember { mutableStateOf("") }
    Column(
        modifier = Modifier.minWidth(200.px)
            .overflow(Overflow.Auto)
            .scrollBehavior(ScrollBehavior.Smooth)
    ) {


        HedefColumn(
            title = "Haftalık Özet"
        ) {
            PeriodScores(kitap, score)
            dersMap.filter { it.ders != Values.KITAP_OKUMA }.forEach { map ->
                var color = AppColors.colorDersList[(0..8).random()]

                dersList.find { it.dersad == map.ders }?.let {
                    color = AppColors.colorDersList[it.color]
                }
                Column(
                    modifier = OzetDersColStyle
                        .toModifier()
                        .backgroundColor(color.opacity(0.2f))
                        .fillMaxWidth()
                ) {
                    val soru = map.konulist.sumOf { it.soru }
                    val kalan = map.konulist.sumOf { it.kalan }
                    FaaliyetDersView(map.ders, soru, kalan, showKonu)
                    if (showKonu.value == map.ders) {
                        map.konulist.forEach { faliyet ->
                            FaaliyetKonuView(faliyet)
                        }
                    }
                }
                VDivider()
            }
        }

        HedefColumn(
            title = "Okuduğum Kitaplar"
        ) {
            dersMap.filter { it.ders == Values.KITAP_OKUMA }.forEach { map ->
                Column(
                    modifier = OzetDersColStyle.toModifier().fillMaxWidth()
                        .overflow(overflowY = Overflow.Auto, overflowX = Overflow.Hidden)
                ) {
                    map.konulist.forEach { faliyet ->
                        FaaliyetKitapView(faliyet)
                    }
                }

            }
        }
    }
}
*/

@Composable
fun FaaliyetDersView(
    ders: String, soru: Int, dogru: Double, kalan: Int,
    showKonu: MutableState<String>,
) {
    val complete = (100 * soru) / (soru + kalan)
    val success = (100.0 * dogru) / soru.toDouble()
    Column(modifier = Modifier.fillMaxWidth()) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(
                attrs = Modifier
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(AppColors.TextColor.opacity(0.8f))
                    .toAttrs()
            ) {
                Text(ders.ifEmpty { "Ders belirtilmemiş" })
            }
            Spacer()
            Span(
                attrs = Modifier
                    .fontSize(13.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text("% ")
                Text(complete.toString())
            }

            Box(
                modifier = FaaliyetDersChevStyle.toModifier()
                    .onClick {
                        if (showKonu.value == ders) {
                            showKonu.value = ""
                        } else {
                            showKonu.value = ders
                        }
                    },
                contentAlignment = Alignment.Center
            ) {
                if (showKonu.value == ders) {
                    FaChevronDown(
                        size = IconSize.SM,
                        modifier = Modifier.color(AppColors.DetailBlue)
                    )
                } else {
                    FaChevronRight(size = IconSize.SM, modifier = Modifier.color(AppColors.GrayDark))
                }

            }


        }

        Box(
            modifier = Modifier.fillMaxWidth()
                .height(12.px)
                .backgroundColor(Colors.Gray.opacity(0.7f))
                .borderRadius(r = 8.px)
        ) {
            Box(
                modifier = Modifier.fillMaxWidth(complete.percent)
                    .height(12.px)
                    .backgroundColor(AppColors.PrimaryDark)
                    .borderRadius(r = 8.px)
            ) {

            }
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Span(
                attrs = Modifier
                    .fontSize(13.px)
                    .fontWeight(FontWeight.Medium)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text("Çöz: ")
                Text(soru.toString())
            }
            Span(
                attrs = Modifier
                    .fontSize(13.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(if (success > 85.0) AppColors.NumGreen else if (success > 70.0) AppColors.Orange else AppColors.DarkRed)
                    .toAttrs()
            ) {
                Text("% ")
                Text(success.round(1).toString())
            }

            Span(
                attrs = Modifier
                    .fontSize(13.px)
                    .fontWeight(FontWeight.Medium)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text("Kalan: ")
                Text(kalan.toString())
            }
        }

    }
}

@Composable
fun FaaliyetKonuView(faliyet: KonuMap) {
    val complete = (100 * faliyet.soru) / (faliyet.soru + faliyet.kalan)
    val success = (100.0 * faliyet.puan) / faliyet.soru
    Column(
        modifier = Modifier.fillMaxWidth()
            .padding(2.px)
            .backgroundColor(Colors.White)
            .borderRadius(r = 4.px)
            .margin(bottom = 3.px)
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text(faliyet.konu)
            }
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text("% ")
                Text(complete.toString())
            }
        }

        Box(
            modifier = Modifier.fillMaxWidth()
                .height(6.px)
                .backgroundColor(Colors.Gray.opacity(0.6f))
                .borderRadius(r = 8.px)
        ) {
            Box(
                modifier = Modifier.fillMaxWidth(complete.percent)
                    .height(6.px)
                    .backgroundColor(AppColors.Primary)
                    .borderRadius(r = 8.px)
            ) {

            }
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text("Çöz: ")
                Text(faliyet.soru.toString())
            }
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .fontWeight(FontWeight.SemiBold)
                    .color(if (success > 85.0) AppColors.NumGreen else if (success > 70.0) AppColors.Orange else AppColors.DarkRed)
                    .toAttrs()
            ) {
                Text("% ")
                Text(success.round(1).toString())
            }

            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text("Kalan: ")
                Text(faliyet.kalan.toString())
            }
        }

    }
}

@Composable
fun FaaliyetKitapView(kitap: KitapOzet) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .backgroundColor(AppColors.Primary.opacity(0.2f))
            .borderRadius(r= 8.px)
            .margin(bottom = 1.px)
            .padding(2.px)
        ,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        Span(
            attrs = Modifier
                .fontSize(14.px)
                .fontWeight(FontWeight.Medium)
                .color(AppColors.TextColor.opacity(0.7f))
                .toAttrs()
        ) {
            Text(kitap.kitap)
        }
        Span(
            attrs = Modifier
                .fontSize(13.px)
                .color(AppColors.TextColor.opacity(0.7f))
                .toAttrs()
        ) {
            Text("Sayfa: ")
            Text(kitap.sayfa.toString())
        }
    }

}


@Composable
fun DenemeView(deneme: DenemeData) {
    Row(
        modifier = Modifier.fillMaxWidth()
            .backgroundColor(AppColors.DarkRed.opacity(0.2f))
            .borderRadius(r= 8.px)
            .margin(bottom = 1.px)
            .padding(3.px)
        ,
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Column {
            Span(
                attrs = Modifier
                    .fontSize(14.px)
                    .fontWeight(FontWeight.Medium)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text(deneme.deneme)
            }
            Span(
                attrs = Modifier
                    .fontSize(12.px)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text("Soru Sayısı: ")
                Text(deneme.soru.toString())
            }
        }

        Column(horizontalAlignment = Alignment.End) {
            Span(
                attrs = Modifier
                    .fontSize(13.px)
                    .color(AppColors.TextColor.opacity(0.7f))
                    .toAttrs()
            ) {
                Text("Not: ${deneme.puan.rountTo(1)} / ${deneme.soru} ")
            }
            Span(
                attrs = Modifier
                    .color(AppColors.TextColor.opacity(0.8f))
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .toAttrs()
            ){
                Text("% ${deneme.score}")
            }
        }

    }
}


@Composable
fun PeriodScores(kitap: Int, score: Double) {
    Column(
        modifier = Modifier.fillMaxWidth()
            .backgroundColor(AppColors.BallonBlue)
            .borderRadius(r = 8.px)
            .color(AppColors.TextColor)
            .padding(leftRight = 2.px, topBottom = 4.px),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Span(
            attrs = Modifier
                .fontWeight(FontWeight.Medium)
                .fontSize(15.px).toAttrs()
        ) {
            Text("Skorlarım")
        }
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceAround
        ) {
            Column(
                modifier = Modifier.borderRadius(r = 50.percent)
                    .size(50.px)
                    .backgroundColor(AppColors.BallonYellow.opacity(0.7f)),
                verticalArrangement = Arrangement.Bottom,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                FaBookOpen(
                    size = IconSize.XL,
                    modifier = Modifier.color(AppColors.OpenBookColor)
                )
                Span(
                    attrs = Modifier
                        .margin(top = 10.px)
                        .fontWeight(FontWeight.Medium)
                        .fontSize(15.px).toAttrs()
                ) {
                    Text(kitap.toString())
                }
            }

            Column(
                modifier = Modifier.borderRadius(r = 50.percent)
                    .size(50.px)
                    .backgroundColor(AppColors.BallonYellow.opacity(0.7f)),
                verticalArrangement = Arrangement.Bottom,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                FaAward(
                    size = IconSize.XL,
                    modifier = Modifier.color(AppColors.AwardColor)
                )
                Span(
                    attrs = Modifier
                        .margin(top = 10.px)
                        .fontWeight(FontWeight.Medium)
                        .fontSize(15.px).toAttrs()
                ) {
                    Text(score.toString())
                }
            }
        }
    }


}
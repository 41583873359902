package com.kelimesoft.etutpro.pages.etut

import androidx.compose.runtime.*
import androidx.compose.web.events.SyntheticDragEvent
import com.kelimesoft.etutpro.components.FaEdit
import com.kelimesoft.etutpro.components.MyFaIcon
import com.kelimesoft.etutpro.components.RowButton
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.models.AppData.ViewModel.ogrenciVM
import com.kelimesoft.etutpro.utils.*
import com.kelimesoft.etutpro.viewmodels.EtutVM
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.DragEvent
import org.w3c.dom.HTMLElement
import kotlin.js.Date


@Composable
fun EtutDayBox(
    etutVM: EtutVM, scope: CoroutineScope,
    index: Int, tarih: String,
    onAdd: (EtutType) -> Unit, onEdit: (EtutItem) -> Unit,
    onDrag: (EtutItem) -> Unit, onDrop: (String) -> Unit
) {
    val etutDate = Date(tarih);
    val prevDate = Date().addDays(-14)
    val canAcceptDrop = etutDate.isAfter(prevDate)
    var dragOver by remember { mutableStateOf(false) }
    val days = listOf("Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar")
    var showDeleteEtut: EtutItem? by remember { mutableStateOf(null) }

    var howered by remember { mutableStateOf(false) }

    var saatXpos by remember { mutableStateOf(0.0) }
    var saatYpos by remember { mutableStateOf(0.0) }
    var draggedYOffset by remember { mutableStateOf(0.0) }
    var lastDragId by remember { mutableStateOf("") }
    var newSaat by remember { mutableStateOf("") }

    val columnId = "day-box-$tarih"

    LaunchedEffect(true){
        delay(200)
        val element = document.getElementById(columnId) as? HTMLElement
        if (element != null) {
            val rect = element.getBoundingClientRect()
            saatXpos = rect.left
            //saatYpos = rect.top
        }
    }


    fun calculateDayPuan(tarih: String): Double {
        var dailyPuan = 0.0
        etutVM.etutListMap[tarih]?.forEach { et ->
            if (et.type != EtutType.Kitap.type){
                if (et.soru > 0) dailyPuan += et.soru
                else if  (et.puan > 0) dailyPuan += et.puan
            }
        }
        return dailyPuan
    }


    fun getHourAndMinute(minutes: Int): String {
        var newHours = 4 + (minutes / 60)
        var newMinutes = minutes % 60
        //en yakın 5 dk ayarı
        newMinutes = ((newMinutes + 2) / 5) * 5
        if (newMinutes == 60) {
            newMinutes = 0
            newHours += 1
        }
        newHours %= 24
        return "${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}"
    }
    
    fun setNewSaatDataoOnDrag(dragEvt: SyntheticDragEvent){
        val element = document.getElementById(columnId) as? HTMLElement
        if (element != null) {
            val rect = element.getBoundingClientRect()

            val columnHeight = rect.height
            val yPosition = dragEvt.clientY - rect.top // + dragoffset
            val clampedYPosition = yPosition.coerceIn(0.0, columnHeight)
            // Calculate the position ratio
            val positionRatio = clampedYPosition / columnHeight
            saatYpos = clampedYPosition + 50
            //gündeki dk
            val minutesSinceStart = (positionRatio * 1200).toInt()
           newSaat = getHourAndMinute(minutesSinceStart)
            console.log("dragoffset:", draggedYOffset)
        }

    }


    Box(modifier = Modifier
        .onMouseOver {
            howered = true
        }
        .onMouseLeave {
            howered = false
        }
        .fillMaxWidth()
        .padding(6.px),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(modifier = Modifier
            .position(Position.Relative)
            .fillMaxWidth()
            //.boxShadow(1.px,1.px,1.px,1.px,
            //  color = Colors.DarkBlue.opacity(0.4f))
            //.backgroundColor(Colors.White.opacity(0.9f))
            .id(columnId)
            .borderRadius(r = 6.px)
            .minHeight(120.px)
            .maxWidth(460.px)
            .thenIf(dragOver) {
                Modifier.backgroundColor(Colors.Blue.opacity(0.2f))
            }
            .thenIf(canAcceptDrop) {
                Modifier
                    .onDrop { dropevt ->
                        dropevt.preventDefault()
                        onDrop(newSaat)
                        dragOver = false
                        //draggedYOffset = 0.0
                    }
                    .onDragOver {
                        it.preventDefault()
                        dragOver = true
                        setNewSaatDataoOnDrag(dragEvt = it)

                        //fileExist = true
                    }
                    .onDragLeave {
                        it.preventDefault()
                        dragOver = false
                        newSaat = ""
                        //draggedYOffset = 0.0
                    }



            }


        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .backgroundColor(
                        if (index > 4) AppColors.WeekEndColor.opacity(0.7f) else
                            AppColors.AppBarButonColor.opacity(0.8f)
                    )
                    .margin(bottom = 2.px, top = 4.px)
                    .padding(2.px)
                    .borderRadius(10.px)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.ViewWhite),

                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                //günlük test sayısı

                SpanText(
                    calculateDayPuan(tarih).toString(),
                    modifier = Modifier
                        .fontSize(14.px)
                        .textAlign(TextAlign.Center)
                        .color(Colors.White)
                        .padding(leftRight = 4.px)
                )

                Column(
                    modifier = Modifier.fillMaxWidth()
                        .thenIf(canAcceptDrop) {
                            Modifier
                                .onPaste {
                                    scope.launch {
                                        var uuid: String? = null
                                        if (AppData.appUser.role > 0) {
                                            ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                                        }
                                        etutVM.pasteCopiedEtut(tarih, uuid)
                                    }
                                }
                        },
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    SpanText(
                        days[index],
                        modifier = Modifier
                            .textAlign(TextAlign.Center)
                            .color(Colors.White)
                            .padding(leftRight = 4.px)
                    )

                    SpanText(
                        Date(tarih).toLocaleDateString(),
                        modifier = Modifier
                            .fontSize(12.px)
                            .textAlign(TextAlign.Center)
                            .color(Colors.White.opacity(0.7f))
                    )
                }

                EtutAddMenu(tarih, onSelect = {
                    onAdd(it)
                })
                /*
                SmallAddButton {
                    onAdd()
                }*/
            }

            etutVM.etutListMap[tarih]?.sortedBy { it.saati }?.forEach { etut ->
                EtutRow(etutVM, etut,
                    onEdit = {
                        onEdit(etut)
                    }, onDelete = { del ->
                        showDeleteEtut = if (del) etut
                        else null

                    }, onComplete = {
                        scope.launch {
                            etutVM.etutDone(etut)
                        }
                    }, dragStart = { dragId ->
                        lastDragId = dragId
                        onDrag(etut)
                    })
            }
        }

        if (canAcceptDrop && etutVM.copiedEtut != null && howered) {
            Box(
                modifier = Modifier.fillMaxSize()
                    .borderRadius(r = 8.px)
                    .backgroundColor(AppColors.Gray.opacity(0.2f)),
                contentAlignment = Alignment.Center
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Top
                ) {
                    BSButton(
                        modifier = Modifier
                            .margin(top = 60.px)
                            .opacity(60.percent),
                        text = "Yapıştır",
                        variant = ButtonVariant.PrimaryOutline
                    ) {
                        scope.launch {
                            var uuid: String? = null
                            if (AppData.appUser.role > 0) {
                                ogrenciVM.selectedOgrenci?.let { uuid = it.uuid }
                            }
                            etutVM.pasteCopiedEtut(tarih, uuid)
                        }
                    }
                    BSButton(
                        modifier = Modifier
                            .margin(top = 30.px)
                            .opacity(60.percent),
                        text = "İptal",
                        variant = ButtonVariant.SecondaryOutline
                    ) {
                        etutVM.etutSelectForCopy = null
                        etutVM.copiedEtut = null
                    }
                }

            }
            /*
            RowButton {
                FaPaste(
                    modifier = Modifier.color(AppColors.Gray.opacity(0.9f))
                        .onClick {


                        },
                    size = IconSize.SM
                )
            }*/
        }

        if (showDeleteEtut != null
            && etutVM.etutSelectForCopy == showDeleteEtut
        ) {
            EtutDeleteOnay(onCancel = {
                showDeleteEtut = null
            }, onDelete = {
                scope.launch {
                    etutVM.etutDelete(showDeleteEtut!!)
                    showDeleteEtut = null
                }
            })

        }
        if (dragOver) {
            Box(
                modifier = Modifier
                    .position(Position.Absolute)
                    .top(saatYpos.px) // Adjust for text height
                    .left((saatXpos + 90).px) // Adjust as needed
                    .backgroundColor(AppColors.DarkRed.opacity(0.8f))
                    .padding(leftRight = 8.px, topBottom = 2.px)
                    .color(Colors.White)
                    .fontSize(13.px)
                    .borderRadius(4.px)
                    .padding(6.px)
                    .zIndex(90)
            ) {
                Text("saati: $newSaat")
            }
        }


    }
}


@Composable
fun EtutRow(
    etutVM: EtutVM,
    etut: EtutItem, onEdit: () -> Unit,
    onDelete: (Boolean) -> Unit,
    onComplete: () -> Unit,
    dragStart: (String) -> Unit
) {
    val context = rememberPageContext()
    val dersVM = AppData.ViewModel.dersVM

    val typeIcon = EtutType.entries.find { it.type == etut.type }?.icon ?:  "file-contract"
    var dersText by remember { mutableStateOf(etut.ders) }
    var dersColor by remember { mutableStateOf(AppColors.TextColor.opacity(0.8f)) }
    val dragItemId = "etutDrag-${etut.id}"

    LaunchedEffect(etutVM.etutListMap, ogrenciVM.selectedOgrenci) {
        if (etut.type < 2) {
            dersText = etut.ders
            if (dersVM.dersColorSet.isNotEmpty()) {
                dersVM.dersColorSet.find { it.dersad == etut.ders }?.let {
                    dersColor = AppColors.colorDersList[it.renk]
                }

            }
        }else if (etut.type == EtutType.Kuran.ordinal){
            dersText = "Kur'an Okuma"
            dersColor = AppColors.Primary
        }else if (etut.type == EtutType.Kitap.ordinal){
            dersText = "Kitap Okuma"
            dersColor = AppColors.PrimaryDark
        }else{
            dersColor = AppColors.DarkRed
            dersText = "Sınav / Deneme"
        }

    }

    Box(
        modifier = Modifier.fillMaxWidth()
            .id(dragItemId)
            .borderRadius(r = 4.px)
            .onClick {
                it.stopPropagation()
            }
            .padding(2.px)
            .margin(bottom = 2.px),
        contentAlignment = Alignment.CenterEnd
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .draggable(if (etut.done) Draggable.False else Draggable.True)
                .onDragStart {
                    dragStart(dragItemId)
                }
                .minHeight(80.px)
                .onClick {
                    onDelete(false)
                    etutVM.selectForCopy(etut)
                    Funcs.closeDropDowns()

                }
                .boxShadow(
                    1.px,
                    1.px,
                    1.px,
                    1.px,
                    color = if (etut.done) AppColors.PrimaryDark.opacity(0.2f) else Colors.DarkBlue.opacity(0.2f)
                )
                .onCopy {
                    etutVM.copiedEtut = etut
                    if (AppData.appUser.role > 0){
                        ogrenciVM.selectedOgrenci?.let {
                            etutVM.copiedUserUuid = it.uuid
                        }
                    }


                }
                .backgroundColor(
                    if (etut.done) AppColors.PrimaryDark.opacity(0.1f)
                    else if (etut.id == etutVM.etutSelectForCopy?.id) Colors.White.opacity(0.6f) else Colors.White
                )
                .border(
                    if (etut.id == etutVM.etutSelectForCopy?.id) 0.5.px else 0.px,
                    color = Colors.Gray.opacity(0.5f),
                    style = LineStyle.Ridge
                )
                .padding(1.px)
                .borderRadius(r = 4.px),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            //CircleBox(modifier = Modifier.backgroundColor()) {  }
            Row(modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically) {
                if (etut.type == EtutType.Kuran.ordinal) {
                    Image(modifier = Modifier
                        .size(15.px),
                        src = Images.Kuran, alt = "Kutsal kitab Kuranı okumak")
                }else{
                    MyFaIcon(Modifier.color(dersColor), icon = typeIcon)
                }

                SpanText(
                    dersText,
                    modifier = Modifier.fillMaxWidth()
                        .margin(left = 2.px)
                        .color(dersColor)
                        .fontSize(13.px)
                        .fontWeight(FontWeight.Medium)
                        .padding(1.px)
                )
                Spacer()
                Row(
                    modifier = Modifier.backgroundColor(Colors.White)
                        .padding(1.px)
                        .borderRadius(r = 6.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    FaClock(modifier = Modifier.color(AppColors.TextColor), size = IconSize.SM)
                    SpanText(
                        etut.saati,
                        modifier = Modifier.fillMaxWidth()
                            .margin(left = 3.px)
                            .color(dersColor)
                            .fontSize(12.px)
                            .fontWeight(FontWeight.Normal)
                            .padding(1.px)
                    )
                }

            }


            SpanText(
                etut.konu,
                modifier = Modifier.fillMaxWidth()
                    .color(AppColors.TextColor.opacity(0.9f))
                    .fontSize(14.px)
                    .fontWeight(FontWeight.SemiBold)
                    .padding(1.px)
            )

            if (etut.soru > 0) {
                Row(modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.Bottom) {
                    if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) {
                        SpanText(
                            "Sayfa Sayısı: ${etut.soru}",
                            modifier = Modifier.margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) 15.px else 13.px)
                                .padding(1.px)
                        )
                    }else {
                        SpanText(
                            if (etut.done) "Soru: ${etut.soru}" else "Soru Sayısı: ${etut.soru}",
                            modifier = Modifier.margin(right = 6.px)
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(if (etut.type == EtutType.Kitap.ordinal || etut.type == EtutType.Kuran.ordinal) 15.px else 13.px)
                                .padding(1.px)
                        )
                    }

                    if (etut.done && etut.puan > 0
                        && (etut.type == EtutType.KonuTest.ordinal || etut.type == EtutType.Deneme.ordinal)){
                        SpanText(
                            "D: ${etut.dogru},",
                            modifier = Modifier
                                .color(AppColors.NumGreen)
                                .fontSize(12.px)
                                .padding(1.px)
                        )
                        SpanText(
                            "Y: ${etut.yanlis}",
                            modifier = Modifier
                                .margin(right = 6.px)
                                .color(AppColors.DarkRed)
                                .fontSize(12.px)
                                .padding(1.px)
                        )
                        SpanText(
                            "Not %: ${((100 * etut.puan) / etut.soru).rountTo(1)}",
                            modifier = Modifier
                                .color(AppColors.TextColor)
                                .fontWeight(FontWeight.SemiBold)
                                .fontSize(13.px)
                                .padding(1.px)
                        )
                    }
                }

            }

            if (etut.kaynak.isNotEmpty()) {
                Row(modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                    ) {
                    SpanText(
                        etut.kaynak,
                        modifier = Modifier
                            .color(AppColors.TextColor)
                            .fontSize(14.px)
                            .fontWeight(FontWeight.Medium)
                            .padding(1.px)
                    )
                    if (etut.sayfa.isNotEmpty()){
                        SpanText(
                            " (syf: ${etut.sayfa})",
                            modifier = Modifier
                                .color(AppColors.TextColor.opacity(0.6f))
                                .fontSize(13.px)
                                .padding(1.px)
                        )
                    }
                }

            }

            if (etut.link.isNotEmpty()) {
                Row(modifier = Modifier
                    .backgroundColor(AppColors.AppBarButonColor)
                    .margin(4.px)
                    .padding(leftRight = 6.px, topBottom = 4.px)
                    .cursor(Cursor.Pointer)
                    .onClick {
                        context.router.navigateTo(etut.link)
                    }
                    .borderRadius(r = 10.px),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    var linkTitle = "Dökümanı Çalış"
                    if (etut.type == EtutType.KonuTest.ordinal) {
                        linkTitle = "Teste Başla"
                    }
                    if (etut.link.isVideo()){
                        linkTitle = "Video İzle"
                        FaYoutube(modifier = Modifier.color(AppColors.Red), size = IconSize.XL)
                    }else{
                        MyFaIcon(modifier = Modifier.color(AppColors.ViewWhite), icon ="book-reader",
                            IconCategory.SOLID, IconSize.XL)
                    }
                    SpanText(
                        linkTitle,
                        modifier = Modifier.fillMaxWidth()
                            .margin(leftRight = 4.px)
                            .color(AppColors.ViewWhite)
                            .fontSize(14.px)
                            .padding(1.px)
                    )
                }


            }

            //Box(modifier = Modifier.fillMaxWidth().height(1.px).backgroundColor(Colors.Gray.opacity(0.4f)))
        }

        EtutButtons(etut, etut.id == etutVM.etutSelectForCopy?.id, onEdit = {
            onEdit()
        }, onDelete = {
            onDelete(true)
        }, onComplete = {
            onComplete()
        }, onCopy = {
            etutVM.copiedEtut = etut
            if (AppData.appUser.role > 0){
                ogrenciVM.selectedOgrenci?.let {
                    etutVM.copiedUserUuid = it.uuid
                }
            }
        })
    }

}


@Composable
fun EtutButtons(
    etut: EtutItem, selected: Boolean,
    onEdit: () -> Unit, onCopy: () -> Unit,
    onDelete: () -> Unit, onComplete: () -> Unit
) {

    val userRole = AppData.appUser.role
    Column(
        modifier = Modifier.fillMaxHeight(),
        horizontalAlignment = Alignment.End,
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        if (selected) {
            RowButton {
                FaCopy(
                    modifier = Modifier.color(AppColors.Gray.opacity(0.9f))
                        .onClick {
                            onCopy()
                        },
                    size = IconSize.SM
                )
            }
        }
        Row {
            if (selected) {
                if (!etut.done) {
                    RowButton {
                        FaEdit(
                            modifier = Modifier
                                .color(AppColors.Gray.opacity(0.9f))
                                .onClick {
                                    onEdit()
                                },
                            size = IconSize.SM
                        )
                    }
                }

            }
        }




        Row(
            modifier = Modifier.margin(bottom = 6.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (selected && (userRole == etut.addBy || etut.addBy == 0)) {
                RowButton(modifier = Modifier.margin(left = 10.px)) {
                    FaTrash(
                        modifier = Modifier
                            .color(AppColors.Red.opacity(0.8f))
                            .onClick {
                                onDelete()
                            },
                        size = IconSize.SM
                    )
                }
            }
            EtutDoneButon(etut){
                onComplete()
            }

            /*

            RowButton(modifier = Modifier.margin(left = 10.px)) {
                FaCheck(
                    modifier = Modifier
                        .color(if (etut.done) AppColors.Primary.opacity(0.9f) else AppColors.Gray.opacity(0.9f))
                        .onClick {
                            onComplete()
                        },
                    size = IconSize.XL,

                    )
            }

             */
        }


    }
}


@Composable
fun EtutDeleteOnay(onCancel: () -> Unit, onDelete: () -> Unit) {
    Column(
        modifier = Modifier
            .zIndex(91)
            .fillMaxWidth()
            .boxShadow(1.px, 1.px, 1.px, 1.px, color = Colors.DarkBlue.opacity(0.4f))
            .backgroundColor(Colors.White)
            .padding(4.px)
            .borderRadius(r = 6.px)
    ) {
        Span(
            attrs = Modifier
                .fontSize(16.px)
                .color(AppColors.DarkRed)
                .margin(topBottom = 4.px)
                .toAttrs()
        ) {
            Text("Etüt görevi silinecek?")
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            BSButton(
                text = "İptal",
                variant = ButtonVariant.SecondaryOutline,
                size = ButtonSize.Small
            ) {
                onCancel()
            }

            BSButton(
                text = "Sil",
                variant = ButtonVariant.Danger,
                size = ButtonSize.Small
            ) {
                onDelete()

            }
        }

    }
}


package com.kelimesoft.etutpro.pages.ozet

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.HedefPeriod
import com.kelimesoft.etutpro.models.opacity
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun Hedeflerim(addHedef: (HedefPeriod) -> Unit) {
    Column(modifier = Modifier.fillMaxHeight()) {
        AnaHedef()
        AylikHedefler {
            addHedef(HedefPeriod.Ay)
        }
        HaftalikHedefler{
            addHedef(HedefPeriod.Hafta)
        }
    }
}

@Composable
fun AnaHedef() {
    HedefColumn(title = "Ana Hedefim"){
        Span(attrs = Modifier
            .fontSize(16.px)
            .padding(4.px)
            .fontWeight(FontWeight.SemiBold)
            .color(AppColors.TextColor)
            .toAttrs()) {
            Text("Fen Lisesini kazanmak istiyorum")
        }
    }

}

@Composable
fun AylikHedefler(add: () -> Unit) {
    HedefColumn(modifier = Modifier.maxHeight(400.px)
        .minHeight(200.px), title = "Aylık Hedeflerim"){

        Spacer()

        BSButton(
            modifier = Modifier.maxHeight(30.px),
            text = "+ Yeni Hedef",
            variant = ButtonVariant.SuccessOutline,
            size = ButtonSize.Small
        ){
            add()
        }

    }
}


@Composable
fun HaftalikHedefler(add: () -> Unit) {
    HedefColumn(modifier =
    Modifier.maxHeight(400.px).minHeight(200.px),
        title = "Haftalık Hedeflerim"){

        Spacer()

        BSButton(
            modifier = Modifier.maxHeight(30.px),
            text = "+ Yeni Hedef",
            variant = ButtonVariant.SuccessOutline,
            size = ButtonSize.Small
        ){
            add()
        }

    }
}


@Composable
fun HedefColumn(modifier: Modifier = Modifier,  title: String, content: @Composable () -> Unit) {
    Box(modifier = Modifier.fillMaxWidth()
        .minHeight(60.px)
        .padding(leftRight = 2.px)) {
        Column(modifier = modifier
            .fillMaxWidth()
            .backgroundColor(Colors.White)
            .padding(2.px)
            .margin(2.px)
            .borderRadius(10.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SpanText(
                title,
                modifier = Modifier
                    .fillMaxWidth()
                    .fontSize(16.px)
                    .backgroundColor(AppColors.AppBarButonColor.opacity(0.9f))
                    .margin(bottom = 6.px)
                    .padding(2.px)
                    .borderRadius(10.px)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.ViewWhite)
                    .fontWeight(FontWeight.SemiBold)
            )
            content()
        }
    }


}


@Composable
fun OzetSubFaaliyet(modifier: Modifier = Modifier,
                    title: String,
                    fontSize: CSSSizeValue<CSSUnit.px> = 14.px,
                    content: @Composable () -> Unit) {

    Box(modifier = Modifier.fillMaxWidth()
        .minHeight(60.px)
        .padding(leftRight = 2.px)) {
        Column(modifier = modifier
            .fillMaxWidth()
            .backgroundColor(Colors.White)
            .padding(2.px)
            .margin(2.px)
            .borderRadius(8.px),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            SpanText(
                title,
                modifier = Modifier
                    .fillMaxWidth()
                    .fontSize(fontSize)
                    .backgroundColor(AppColors.AppBarButonColor.opacity(0.8f))
                    .margin(bottom = 6.px)
                    .padding(2.px)
                    .borderRadius(8.px)
                    .textAlign(TextAlign.Center)
                    .color(AppColors.ViewWhite)
                    .fontWeight(FontWeight.SemiBold)
            )
            content()
        }
    }


}




package com.kelimesoft.etutpro.models

import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors

object AppColors {

    val AppBarColor get() = Color.rgba(12, 19,33, 1.0f)

    val AppBarColorBlue get() = Color.rgba(44, 44, 84,1.0f)

    val AppBarButonColor get() = Color.rgba(26, 33,47, 1.0f)

    val BallonBlue get() = Color.rgba(226,235,255, 1.0f)
    val BallonYellow get() = Color.rgba(255,237,227, 1.0f)
    val BallonMor get() = Color.rgba(204,206,255, 1.0f)
    val OpenBookColor get() = Color.rgba(255, 190, 118, 1.0f)

    val WeekEndColor get() = Color.rgba(247, 91,0, 1.0f)

    val AwardColor get() = Color.rgba(42,197,101, 1.0f)


    val ViewWhite get() = Color.rgba(238,239,239, 1.0f)

    val Primary get() = Color.rgba(43, 203, 186,1.0f)
    val PrimaryDark get() = Color.rgba(15, 185, 177,1.0f)
    val Background get() = Color.rgba(155, 165, 175,1.0f)
    val TextColor get() = Color.rgba(34, 47, 62,0.8f)
    val GrayDark get() = Color.rgba(87, 101, 116,1.0f)
    val Gray get() = Color.rgba(131, 149, 167,1.0f)
    val LightBlue get() = Color.rgba(200, 214, 229,1.0f)
    val BarColor get() = Color.rgba(223, 228, 234,1.0f)
    val DropMenu get() = Color.rgba(245, 246, 250,1.0f)
    val Red get() = Color.rgba(255, 107, 107,1.0f)
    val DarkRed get() = Color.rgba(229, 80, 57,1.0f)
    val DetailBlue get() = Color.rgba(0, 151, 230,1.0f)

    val NumRed get() = Color.rgba(255, 107, 107,1.0f)
    val NumBlue get() = Color.rgba(10, 189, 227,1.0f)
    val NumGreen get() = Color.rgba(0, 210, 211,1.0f)
    val NumOrange get() = Color.rgba(255, 159, 67,1.0f)
    val NumYellow get() = Color.rgba(255, 177, 66,1.0f)

    val NumPurple get() = Color.rgba(112, 111, 211,1.0f)
    val NumGreen2 get() = Color.rgba(51, 217, 178,1.0f)

    val UrgentColor1 get() = Color.rgba(255, 107, 107, 1.0f)
    val UrgentColor2 get() = Color.rgba(255, 159, 67, 1.0f)
    val UrgentColor3 get() = Color.rgba(87, 101, 116, 1.0f)

    //val DesrBlue get() = Color.rgba(223, 249, 251,1.0f)
    val DesrBlue = Colors.White


    val Orange get() = Color.rgba(255, 165, 2,1.0f)
    val Tomato get() = Color.rgba(255, 99, 72,1.0f)
    val DarkPurple get() = Color.rgba(165, 94, 234,1.0f)
    val Coral get() = Color.rgba(255, 127, 80,1.0f)
    val Watermelon get() = Color.rgba(255, 71, 87,1.0f)
    val Skyblue get() = Color.rgba(112, 161, 255,1.0f)
    val SaturatedSky get() = Color.rgba(83, 82, 237,1.0f)
    val Grisaille get() = Color.rgba(87, 96, 111,1.0f)
    val WaterfallGreen get() = Color.rgba(56, 173, 169,1.0f)

    var GradientGray  = linearGradient(dir = LinearGradient.Direction.ToBottomRight, from= GrayDark, to=Gray)
    var GradientPrimary  = linearGradient(dir = LinearGradient.Direction.ToTopLeft, from= AppBarColor.opacity(0.6f), to= AppBarColorBlue.opacity(0.4f))

    val colorDersList = listOf(
        Grisaille, Orange, Tomato, Coral, Watermelon, DarkPurple, SaturatedSky, Skyblue, WaterfallGreen
    )
}

fun Color.Rgb.opacity(float: Float): Color {
    return this.copyf(alpha = float)
}
package com.kelimesoft.etutpro.components

import androidx.compose.runtime.Composable
import com.kelimesoft.etutpro.models.opacity
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.thenIf
import org.jetbrains.compose.web.css.px

@Composable
fun VDivider(modifier: Modifier = Modifier) {
    Box(modifier = Modifier
        .fillMaxWidth().height(1.px)
        .backgroundColor(Colors.Gray.opacity(0.4f))
        .then(modifier)
    )
}
package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.AppData
import com.kelimesoft.etutpro.models.EtutItem
import com.kelimesoft.etutpro.models.EtutType
import com.kelimesoft.etutpro.models.FuncResult
import com.kelimesoft.etutpro.network.ApiResponse
import com.kelimesoft.etutpro.network.EtutListResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.utils.addDays
import com.kelimesoft.etutpro.utils.dateToDbStr
import com.kelimesoft.etutpro.utils.round
import com.kelimesoft.etutpro.utils.rountTo
import kotlinx.serialization.json.Json
import kotlin.js.Date


class EtutVM {
    var weekStartDate by mutableStateOf(getWeekStartDay(Date()))

    var weekDays: List<String> by mutableStateOf(makeWeekDayList(weekStartDate))

    var userAward by mutableStateOf(0.0)
    var kitapSayfa by mutableStateOf(0)


    var allEtutList: List<EtutItem> = listOf()

    var etutListMap by mutableStateOf(mapOf<String, List<EtutItem>>())
    var etutListDersMap by mutableStateOf(mapOf<String, List<EtutItem>>())

    var etutSelectForCopy: EtutItem? by mutableStateOf(null)
    var copiedEtut: EtutItem? by  mutableStateOf(null)
    var copiedUserUuid: String? by  mutableStateOf("")
    var gunEtutList by mutableStateOf(listOf<EtutItem>())


    var searchText: String = ""

    var searchResult by mutableStateOf(listOf<EtutItem>())


    suspend fun getEtutList(uuid: String? = null) {
        if (allEtutList.isEmpty()){
            val json = Json {
                ignoreUnknownKeys = true
            }
            RestApi.getEtutList(weekStartDate.dateToDbStr(), uuid)?.let { res ->
                //console.log("etutlist:", res)
                json.decodeFromString<EtutListResponse>(res).let { eres ->
                    if (eres.data != null) {
                        allEtutList = eres.data
                        handleEtutList(true)
                    }
                }
            }
        }
    }

    suspend fun gotoNextWeek(){
        allEtutList = listOf()
        weekStartDate = getWeekStartDay(weekStartDate.addDays(7))
        weekDays = makeWeekDayList(weekStartDate)
        etutListMap = mapOf()
        etutListDersMap = mapOf()
        if (AppData.appUser.role == 0){
            getEtutList()
        }else if (AppData.ViewModel.ogrenciVM.selectedOgrenci != null){
            getEtutList(AppData.ViewModel.ogrenciVM.selectedOgrenci!!.uuid)
        }
    }

    suspend fun replanUnfinished(): String? {
        var uuid = AppData.appUser.uuid
        if (AppData.appUser.role > 0) {
            if (AppData.ViewModel.ogrenciVM.selectedOgrenci != null) {
                uuid = AppData.ViewModel.ogrenciVM.selectedOgrenci!!.uuid
            }else{
                return "Öğrenci Seçiniz!"
            }
        }
        RestApi.replanUnfinished(weekStartDate.dateToDbStr(), uuid)?.let { res ->
            //console.log("etutlist:", res)
            Json.decodeFromString<EtutListResponse>(res).let { eres ->
                if (eres.data != null) {
                    allEtutList = eres.data
                    handleEtutList(true)
                }
            }
        }
        return null
    }

    suspend fun gotoPreviousWeek(){
        allEtutList = listOf()
        weekStartDate = getWeekStartDay(weekStartDate.addDays(-7))
        weekDays = makeWeekDayList(weekStartDate)
        etutListMap = mapOf()
        etutListDersMap = mapOf()
        if (AppData.appUser.role == 0){
            getEtutList()
        }else if (AppData.ViewModel.ogrenciVM.selectedOgrenci != null){
            getEtutList(AppData.ViewModel.ogrenciVM.selectedOgrenci!!.uuid)
        }

    }


    suspend fun addNewEtut(etutItem: EtutItem, uuid: String? = null,
                           docs: List<String> = listOf(),
                           uuids: List<String> = listOf()): FuncResult {
        val vres = validateEtutItem(etutItem)
        if (vres != null){
            return FuncResult(false, vres)
        }
        /*
        if (!Funcs.checkForm("etutadd-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }*/
        console.log("uuids:", uuids)
        RestApi.addNewEtut(etutItem, weekStartDate.dateToDbStr(), uuid, docs, uuids)?.let { res ->
            Json.decodeFromString<EtutListResponse>(res).let { eres ->
                if (eres.data != null) {
                    allEtutList = eres.data
                    handleEtutList()
                    return FuncResult(true)
                } else if (eres.error != null) {
                    return FuncResult(false, eres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    suspend fun getGunEtutList(uuid: String? = null) {
        if (gunEtutList.isEmpty()){
            RestApi.getGunEtutList(Date().dateToDbStr(), uuid)?.let { res ->
                //console.log("etutlist:", res)
                Json.decodeFromString<EtutListResponse>(res).let { eres ->
                    if (eres.data != null) {
                        gunEtutList = eres.data
                    } else if (eres.error != null) {

                    }
                }
            }
        }
    }

    suspend fun editExistingEtut(etutItem: EtutItem, uuid: String? = null): FuncResult{
        val vres = validateEtutItem(etutItem)
        if (vres != null){
            return FuncResult(false, vres)
        }
        /*
        if (!Funcs.checkForm("etutadd-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }*/
        RestApi.addNewEtut(etutItem, weekStartDate.dateToDbStr(), uuid)?.let { res ->
            Json.decodeFromString<EtutListResponse>(res).let { eres ->
                if (eres.data != null) {
                    val index = allEtutList.indexOfFirst { it.id == etutItem.id }
                    val newList = allEtutList.toMutableList()
                    newList[index] = etutItem
                    allEtutList = newList.toList()
                    handleEtutList()
                    return FuncResult(true)
                } else if (eres.error != null) {
                    return FuncResult(false, eres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }


    suspend fun getSearchEtut(text: String, uuid: String? = null) {
        //console.log("searchtext:", text)
        if (text.isNotEmpty())
            RestApi.getEtutSearch(text, uuid)?.let { res ->
                //console.log("etutlist:", res)
                Json.decodeFromString<EtutListResponse>(res).let { eres ->
                    if (eres.data != null) {
                        searchText = text
                        searchResult = eres.data
                    }
                }
            }
    }


    suspend fun editEtutWithDrag(etutItem: EtutItem, uuid: String? = null){
        RestApi.addNewEtut(etutItem, weekStartDate.dateToDbStr(), uuid)?.let { res ->
            Json.decodeFromString<EtutListResponse>(res).let { eres ->
                if (eres.data != null) {
                    val index = allEtutList.indexOfFirst { it.id == etutItem.id }
                    val newList = allEtutList.toMutableList()
                    newList[index] = etutItem
                    allEtutList = newList.toList()
                    handleEtutList()
                }
            }
        }
    }


    suspend fun etutDone(etutItem: EtutItem, fromOzet: Boolean = false): FuncResult{
        var score = 0.0
        var dogru = 0
        var yanlis = 0
        if(etutItem.type == EtutType.Deneme.ordinal || etutItem.type == EtutType.KonuTest.ordinal){
            score = etutItem.puan
            dogru = etutItem.dogru
            yanlis = etutItem.yanlis
        }
        RestApi.etutCompleted(etutItem.id, !etutItem.done, score, dogru, yanlis)?.let { res ->
            Json.decodeFromString<ApiResponse>(res).let { eres ->
                if (eres.data != null) {
                    updateAllList(etutItem, fromOzet)
                    handleEtutList(true)
                    return FuncResult(true)
                } else if (eres.error != null) {
                    return FuncResult(false, eres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    suspend fun etutDelete(etutItem: EtutItem){
        RestApi.deleteEtut(etutItem.id)?.let { res ->
            Json.decodeFromString<ApiResponse>(res).let { eres ->
                if (eres.data != null) {
                    etutDeleteUpdate(etutItem)
                    handleEtutList(true)
                }
            }
        }
    }


    private fun getWeekStartDay(date: Date): Date{
        var firstweekday = date.getDay()
        if (firstweekday == 0) firstweekday = 7
        return date.addDays(-(firstweekday) + 1)
    }

    private fun makeWeekDayList(start: Date): List<String>{
        val daysList: MutableList<String> = mutableListOf()
        daysList.add(start.dateToDbStr())
        (1..6).forEach {
            val ndate = start.addDays(it)
            daysList.add(ndate.dateToDbStr())
        }
        return daysList
    }

    private fun handleEtutList(calculate: Boolean = false){
        etutListMap = allEtutList.groupBy { it.tarih }
        etutListDersMap = allEtutList.groupBy { it.ders }
        if (calculate){
            //kullanıcı award puan hesapla
            var puan = 0.0
            var kitap = 0
            allEtutList.filter { it.done }.forEach {
                if (it.type == EtutType.Kitap.ordinal) {
                    //kitap okumadan max 8 puan almak için
                    if (it.soru > 0) kitap += if (it.soru > 64) 64 else it.soru
                }else if (it.type == EtutType.Kuran.ordinal){
                        //kitap okumadan max 8 puan almak için
                    puan += it.soru * 5
                }else{
                    if (it.puan > 0) puan += it.puan
                    else if(it.soru > 0) puan += it.soru
                }
            }
            userAward = (puan + (kitap / 8).toDouble()).rountTo(1)
            kitapSayfa = kitap
        }
        etutListMap[Date().dateToDbStr()]?.let { gunlist ->
            gunEtutList = gunlist
        }
    }

    private fun updateAllList(old: EtutItem, fromOzet: Boolean = false){
        val netut = EtutItem(id = old.id, ders = old.ders, saati = old.saati,
            konu = old.konu, kaynak = old.kaynak,
            soru = old.soru, puan = old.puan, dogru = old.dogru, yanlis = old.yanlis,
            type = old.type, link = old.link, sayfa = old.sayfa, tarih = old.tarih,
            addBy = old.addBy, done = !old.done )
        var nEtutList = allEtutList.toMutableList()
        nEtutList.indexOfFirst { it.id == netut.id }.let { idx ->
            try {
                nEtutList[idx] = netut
                allEtutList = nEtutList.toList()
            }catch (_:Exception){}

        }
        if (fromOzet){

            val gEtutList = gunEtutList.toMutableList()
            gEtutList.indexOfFirst { it.id == netut.id }.let { idx ->
                //eğer index mevcut değilse -1 sonucu
                try {
                    gEtutList[idx] = netut
                    gunEtutList = gEtutList.toList()
                }catch (_:Exception){}

            }
        }

        //println(etutListMap.toString())
    }

    private fun etutDeleteUpdate(old: EtutItem){
        var nEtutList = allEtutList.toMutableList()
        nEtutList.remove(old)
        allEtutList = nEtutList.toList()
        //println(etutListMap.toString())
    }

    private fun validateEtutItem(etutItem: EtutItem): String? {
        if (etutItem.type == EtutType.KonuTest.type || etutItem.type == EtutType.Konu.type){
            if (etutItem.ders.isEmpty()) return "Ders Seçiniz!"
            /*
            if (etutItem.type == EtutType.Konu.type
                && etutItem.link.isEmpty()) return "Video linkini ekleyiniz!"*/
        }
        return null
    }

    fun selectForCopy(etut: EtutItem){
        if (etut.id == etutSelectForCopy?.id){
            copiedEtut = null
            etutSelectForCopy = null
            copiedUserUuid = ""
        }else{
            copiedEtut = null
            etutSelectForCopy = etut
        }
    }


    suspend fun pasteCopiedEtut(tarih: String, uuid: String? = null){
        copiedEtut?.let {
            try {
                if (it.tarih == tarih && copiedUserUuid == uuid) return
                val newEtut = EtutItem(id = 0, ders = it.ders, saati = it.saati,
                    konu = it.konu, kaynak = it.kaynak, soru = it.soru, puan = it.puan, dogru = it.dogru, yanlis = it.yanlis,
                    type = it.type, link = it.link, sayfa = it.sayfa, tarih = tarih, addBy = AppData.appUser.role)
                RestApi.addNewEtut(newEtut, weekStartDate.dateToDbStr(), uuid)?.let { res ->
                    Json.decodeFromString<EtutListResponse>(res).let { eres ->
                        if (eres.data != null) {
                            allEtutList = eres.data
                            handleEtutList()
                        }

                    }
                }
            }finally {
                etutSelectForCopy = null
                copiedEtut = null
                copiedUserUuid = ""

            }

        }
    }


    fun findEtutStartTime(date: String): String{
        console.log("find:",date)
        etutListMap[date]?.let { etuts ->
            etuts.maxByOrNull { it.saati }?.let { last ->
                var addMin = 30
                if (last.soru > 0){
                    addMin = (last.soru.toDouble() * 1.9).toInt()
                }
                return addMinutesToTime(last.saati, addMin)
            }
        }
        return "06:15"
    }
    private fun addMinutesToTime(timeStr: String, minutesToAdd: Int): String {
        val (hours, minutes) = timeStr.split(":").map { it.toInt() }
        var totalMinutes = (hours * 60) + minutes + minutesToAdd
        var newHours = totalMinutes / 60
        var newMinutes = totalMinutes % 60
        newMinutes = ((newMinutes + 9) / 10) * 10
        console.log("find-new:",newHours, newMinutes)

        if (newMinutes == 60) {
            newMinutes = 0
            newHours += 1
        }
        newHours %= 24
        return "${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}"
    }
}
package com.kelimesoft.etutpro.styles

import com.kelimesoft.etutpro.models.AppColors
import com.kelimesoft.etutpro.models.opacity
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover

import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

val OzetDersColStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .borderRadius(r = 8.px)
            .padding(2.px)
            .margin(1.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .opacity(90.percent)
            .backgroundColor(Colors.Gray.opacity(0.2f))
    }
}

val OzetEtutRowStyle = CssStyle {
    base {
        Modifier
            .backgroundColor(Colors.Transparent)
            .borderRadius(r = 6.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms),
            )
    }
    hover {
        Modifier
            .opacity(90.percent)
            .backgroundColor(Colors.Gray.opacity(0.2f))
    }
}

val SearchBarStyles = CssStyle{
    base {
        Modifier
            .minWidth(160.px)
            .borderRadius(r = 6.px)
            .margin(topBottom = 4.px)
            .padding(4.px)
            .border(0.5.px, color = AppColors.Gray.copyf(alpha = 0.4f), style = LineStyle.Solid)
            .color(AppColors.TextColor)
            .fontSize(14.px)
            .boxShadow(0.px,0.px,null,null, null)
            .backgroundColor(AppColors.DropMenu.copyf(alpha = 0.6f))
            .transition(CSSTransition(property = "border", duration = 500.ms))
    }

    cssRule("::placeholder"){
        Modifier
            .fontSize(12.px)
            .color(AppColors.TextColor.copyf(alpha = 0.7f))
    }
}

val DropDownStyle = CssStyle {
    base {
        Modifier
            .width(60.px)
            .borderRadius(r = 4.px)
            .margin(2.px)
            .padding(8.px)
            .border(0.5.px, color = AppColors.Gray.copyf(alpha = 0.3f), style = LineStyle.Solid)
            .color(AppColors.TextColor)
            .fontSize(15.px)
            .backgroundColor(AppColors.LightBlue.copyf(alpha = 0.2f))
            .transition(CSSTransition(property = "border", duration = 500.ms))
    }
}

val DropMenuItemStyle = CssStyle {
    base {
        Modifier
            .fillMaxWidth()
            .padding(4.px)
            .cursor(Cursor.Pointer)
            .border(0.px)
            .backgroundColor(Colors.Transparent)
            .borderRadius(r = 4.px)
            .transition(
                CSSTransition(property = "background", duration = 200.ms)
            )
    }

    hover {
        Modifier
            .backgroundColor(AppColors.GrayDark.copyf(alpha = 0.2f))
    }
}



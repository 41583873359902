package com.kelimesoft.etutpro.models

import kotlinx.serialization.Serializable


enum class HedefPeriod {
    Genel, TimePeriod, Ay, Hafta
}
enum class HedefType(val text: String) {
    KonuTesti(Values.KONU_TEST),
    GenelDeneme(Values.GENEL_DENEME),
    KitapOkuma(Values.KITAP_OKUMA),
}


@Serializable
data class Hedef(
    val id: Long,
    val period: HedefPeriod,
    val type: HedefType, //seçim
    var ders: String,
    //kitap okuma halinde sayfa
    val soru: Int,
    var notlar: String,
    var start: String,
    var end: String
)

@Serializable
data class NewHedefBinding(
    var uuid: String = AppData.appUser.uuid,
    val hedef: Hedef
)

/*
type FaaliyetOzet struct {
    Ay     []DersMap   `json:"ay"`
    Hafta  []DersMap   `json:"hafta"`
    Apuan  int         `json:"apuan"`
    Hpuan  int         `json:"hpuan"`
    ASayfa int         `json:"asyf"`
    HSayfa int         `json:"hsyf"`
    Akitap []KitapData `json:"aklist"`
    Hkitap []KitapData `json:"hklist"`
}*/


package com.kelimesoft.etutpro.components

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.models.*
import com.stevdza.san.kotlinbs.components.BSAlert
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.forms.BSInput
import com.stevdza.san.kotlinbs.models.AlertIcon
import com.stevdza.san.kotlinbs.models.AlertStyle
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun AddOgrenci(
    onClose: () -> Unit,
    onAdded: () -> Unit
) {
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    val userRole = EtutRole.entries.find { it.ordinal == AppData.appUser.role }

    var ogrenciAd by remember { mutableStateOf("") }
    var ogrenciCode by remember { mutableStateOf("") }

    var alertModel: AlertModel? by remember { mutableStateOf(null) }

    val scope = rememberCoroutineScope()

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .maxWidth(500.px)
            .boxShadow(offsetY = 2.px, offsetX = 1.px, color = AppColors.Background)
            .padding(4.px)
            .borderRadius(r = 10.px)
            .backgroundColor(Colors.White)
    ) {
        DialogTitle("Öğrenci Ekle", onClose = onClose)

        Form(
            attrs = Modifier
                .fillMaxWidth()
                .padding(topBottom = 8.px, leftRight = 4.px)
                .id("ogrenci-form")
                .toAttrs()
        ) {


            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Text,
                value = ogrenciAd,
                placeholder = "Öğrenci Adı veya Eposta adresi",
                required = true,
                onValueChange = {
                    ogrenciAd = it.replace(" ", "")
                }
            )



            BSInput(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(bottom = 14.px),
                type = InputType.Text,
                value = ogrenciCode,
                placeholder = "${userRole?.text} Aktivasyon Kodu",
                required = true,
                onValueChange = {
                    ogrenciCode = it
                }
            )

            Row(horizontalArrangement = Arrangement.SpaceBetween) {
                BSButton(
                    text = "Kapat",
                    variant = ButtonVariant.DangerOutline,
                    onClick = {
                        onClose()
                    }
                )
                BSButton(
                    text = "Ekle",
                    variant = ButtonVariant.Success,
                    onClick = {
                        scope.launch {
                            val res = ogrenciVM.addOgrenci(ogrenciAd, ogrenciCode)
                            if (res.result){
                                ogrenciVM.getOgrenciList(true)
                                onAdded()
                            }else{
                                alertModel = AlertModel(res.error ?: "", AlertIcon.Warning, AlertStyle.Danger)
                                delay(3000)
                                alertModel = null
                            }
                        }

                    }
                )
            }
            VDivider(modifier = Modifier.margin(topBottom = 8.px))

            Column(modifier = Modifier.fillMaxWidth()) {
                Span(attrs = Modifier
                    .fontSize(14.px).toAttrs()) {
                    Text("${userRole?.text} hesabına öğrenci ekleyebilmeniz için, ")
                    Span(attrs = Modifier
                        .fontWeight(FontWeight.SemiBold)
                        .toAttrs()) {
                        Text("ÖĞRENCİNİN PROFİLİNDE")
                    }
                    Text(" görünen: ")
                    Span(attrs = Modifier
                        .fontWeight(FontWeight.SemiBold)
                        .toAttrs()) {
                        Text("Öğrenci adını ve ${if (userRole?.ordinal == 1) "Sağ" else "Sol"} üstteki ")
                    }

                    Span(attrs = Modifier
                        .fontWeight(FontWeight.SemiBold)
                        .toAttrs()) {
                        Text("AKTIVASYON KODUNU giriniz.")
                    }

                }

                Span {
                    Text("Aşağıdaki örnek resimde görüldüğü gibi")

                }

                Image(
                    modifier = Modifier
                        .fillMaxWidth()
                        .objectFit(ObjectFit.Fill)
                        .margin(topBottom = 4.px),
                    src = Images.OgrenciEkle,
                    alt = "etut pro ile okulda başarı"

                )
            }

        }
    }


    if (alertModel != null) {
        BSAlert(
            modifier = Modifier.zIndex(99),
            message = alertModel!!.message,
            dismissible = false,
            icon = alertModel!!.icon,
            style = alertModel!!.style

        )
    }

}
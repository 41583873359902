package com.kelimesoft.etutpro.models

import kotlinx.serialization.Serializable

@Serializable
data class DersKaynak(
    val id: Long = 0L,
    var ders: String,
    val isim: String,
    val sayfa: Int
)


@Serializable
data class NewKaynakBinding(
    val kaynak: DersKaynak,
    var uuid: String = AppData.appUser.uuid,
    val uuids: List<String> = listOf()
)


package com.kelimesoft.etutpro.models

import androidx.compose.runtime.CompositionContext
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import kotlinx.serialization.DeserializationStrategy
import org.w3c.dom.CanvasDrawPath


enum class Screens(
    val path: String
) {
    Home("/"),
    Ozet("/ozet"),
    Ders("/ders"),
    Etut("/etut"),
    Profil("/profil"),
    Login("/giris")
}

class Routes {
    var activeRoute by mutableStateOf(Screens.Home)
    fun navigateTo(context: PageContext, dest: Screens, replace: Boolean = false){
        activeRoute = dest
        var repl = replace
        if (dest == Screens.Home) repl = true
        context.router.navigateTo(
            dest.path,
            updateHistoryMode = if (repl) UpdateHistoryMode.REPLACE else UpdateHistoryMode.PUSH,
        )
    }
}
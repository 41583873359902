package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.ApiResponse
import com.kelimesoft.etutpro.network.DersListResponse
import com.kelimesoft.etutpro.network.EtutListResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.utils.dateToDbStr
import kotlinx.serialization.json.Json
import org.w3c.xhr.FormData


class DersVM {
    var dersList = listOf<DersItem>()
    var dersColorSet by mutableStateOf(setOf<DersItem>())
    var daysMapList by mutableStateOf(mapOf<WeekDays,List<DersItem>>())

    var draggedDers: DersItem? = null
    var copiedDers: DersItem? by  mutableStateOf(null)

    var gunuBosDersList by mutableStateOf(listOf<DersItem>())

    suspend fun getDersList(uuid: String? = null) {
        if (dersList.isEmpty()){
            RestApi.getDersList(uuid)?.let { res ->
                Json.decodeFromString<DersListResponse>(res).let { dres ->
                    if (dres.data != null) {
                        dersList = dres.data
                        prepareDaysList()
                    }
                }
            }
        }
    }

    suspend fun pasteCopiedDers(gun: Int, uuid: String? = null){
        copiedDers?.let {
            try {
                val newDers = DersItem(id = 0, dersad = it.dersad,
                    renk = it.renk, gun = gun, 9)
                RestApi.addNewDers(newDers, uuid)?.let { res ->
                    Json.decodeFromString<DersListResponse>(res).let { dres ->
                        if (dres.data != null) {
                            dersList = dres.data
                            prepareDaysList()
                        }
                    }
                }
            }finally {
                copiedDers = null
            }

        }
    }


    suspend fun addNewDers(dersItem: DersItem, uuid: String? = null): FuncResult {
        if (!Funcs.checkForm("ders-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        RestApi.addNewDers(dersItem, uuid)?.let { res ->
            Json.decodeFromString<DersListResponse>(res).let { dres ->
                if (dres.data != null) {
                    dersList = dres.data
                    prepareDaysList()
                    return FuncResult(true)
                } else if (dres.error != null) {
                    return FuncResult(false, dres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }

    suspend fun deleteDers(dersItem: DersItem, uuid: String? = null): FuncResult {
        RestApi.deleteDers(dersItem.id, dersItem.gun, dersItem.sira, uuid)?.let { res ->
            //console.log(res)
            Json.decodeFromString<DersListResponse>(res).let { dres ->
                //qconsole.log(dres)
                if (dres.data != null) {
                    dersList = dres.data
                    prepareDaysList()
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }


    suspend fun updateDraggedDers(sira: Int, gun: Int, uuid: String? = null): FuncResult {
        draggedDers?.let { dders ->
            if (dders.sira == sira && dders.gun == gun){
                return FuncResult(true)
            }
            RestApi.updateDersSira(sira, gun, dders, uuid)?.let { res ->
                Json.decodeFromString<DersListResponse>(res).let { dres ->
                    if (dres.data != null) {
                        dersList = dres.data
                        prepareDaysList()
                        return FuncResult(true)
                    } else if (dres.error != null) {
                        return FuncResult(false, dres.error)
                    }
                }
                draggedDers = null
            }
            return FuncResult(false, "Connection Error")
        }
        return FuncResult(true)


    }


    private fun prepareDaysList(){
        var day1 = arrayListOf<DersItem>()
        var day2 = arrayListOf<DersItem>()
        var day3 = arrayListOf<DersItem>()
        var day4 = arrayListOf<DersItem>()
        var day5 = arrayListOf<DersItem>()
        var dersSet = mutableSetOf<DersItem>()

        var dersNames = mutableSetOf<String>()

        dersList.forEach { item ->
            if (item.gun == 1){
                day1.add(item)
            }else if (item.gun == 2){
                day2.add(item)
            }else if (item.gun == 3){
                day3.add(item)
            }else if (item.gun == 4){
                day4.add(item)
            }else if (item.gun == 5){
                day5.add(item)
            }
            if (!dersNames.contains(item.dersad)){
                dersSet.add(item)
            }
            dersNames.add(item.dersad)

        }

        daysMapList = mapOf(
            WeekDays.Pazartesi to day1.sortedBy { it.sira },
            WeekDays.Sali to day2.sortedBy { it.sira },
            WeekDays.Carsamba to day3.sortedBy { it.sira },
            WeekDays.Persembe to day4.sortedBy { it.sira },
            WeekDays.Cuma to day5.sortedBy { it.sira },
        )
        dersColorSet = dersSet

        /*
        var bosDersler = mutableListOf<DersItem>()

        dersList.forEach {
            var toplam = 0
            toplam += it.day1
            toplam += it.day2
            toplam += it.day3
            toplam += it.day4
            toplam += it.day5
            if (toplam == 0){
                bosDersler.add(it)
            }

        }*/
        //gunuBosDersList = bosDersler.toList()


    }

}